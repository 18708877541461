/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import {
    OrganizationCtlProtoInviteMemberReq,
    OrganizationCtlProtoCreateOrgReq,
    OrganizationCtlProtoGetAccessLevelsReq,
    OrganizationCtlProtoGetFreeClusterCountReq,
    OrganizationCtlProtoGetOrgReq,
    OrganizationCtlProtoGetPayableClusterOptionsReq,
    OrganizationCtlProtoListClustersReq,
    OrganizationCtlProtoListMembersReq,
    OrganizationCtlProtoListProjectsReq,
    OrganizationCtlProtoListTeamsReq,
    OrganizationCtlProtoRemoveMemberReq,
    OrganizationCtlProtoCheckOrgIDReq,
    OrganizationCtlProtoReq,
    OrganizationCtlProtoUpdateOrgReq,
    OrganizationCtlProtoListInvitationsReq,
    OrganizationCtlProtoRevokeInvitationReq,
    OrganizationCtlProtoResendInvitationReq,
    OrganizationCtlProtoInviteMemberProjectAccess,
    OrganizationCtlProtoInviteMemberTeamAccess,
    OrganizationCtlProtoGetMemberReq,
    OrganizationCtlProtoSetMemberAccessLevelReq,
} from "../../../application/protocol/organization-controller";
import { Ok, PartialWithUuid } from "../../concept/base";
import { ApiListResponse, ApiResponse, responseListOf, responseOf } from "../../concept/api-response";
import { Parameters, parametersProtoOf } from "../../concept/common";
import { Cluster, partialClusterOf } from "../../concept/cluster";
import { ProviderOptions, providerOptionsOf } from "../../concept/cluster-options";
import { AccessLevel, accessLevelOf, accessLevelProtoOf } from "../../concept/iam";
import { Invitation, Organization, orgProtoOf, partialInvitationOf, partialOrgOf } from "../../concept/organization";
import { partialProjectOf, Project } from "../../concept/project";
import { partialTeamOf, Team } from "../../concept/team";
import { OrgMember, partialOrgMemberOf } from "../../concept/user";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { stringToBytes } from "../../util";
import { UnsubListener } from "../backend/platform-api-backend.service";
import { ApiService } from "../backend/api.service";

@Injectable({
    providedIn: "root",
})
export class OrgApi {
    constructor(private api: ApiService) {}

    getAccessLevels(orgUuids: string[]): Observable<AccessLevel[]> {
        return this.api.orgReqRes(new OrganizationCtlProtoReq({ getAccessLevels: new OrganizationCtlProtoGetAccessLevelsReq({ orgUuids: orgUuids.map(stringToBytes) }) }))
            .pipe(map((res) => res.getAccessLevels.accessLevels.map(accessLevelOf)));
    }

    createOrg(org: Partial<Organization>): Observable<Ok> {
        return this.api.orgReq(new OrganizationCtlProtoReq({
            createOrg: new OrganizationCtlProtoCreateOrgReq({ org: orgProtoOf(org) })
        }));
    }

    checkOrgId(orgId: string): Observable<{ exists: boolean }> {
        return this.api.orgReqRes(new OrganizationCtlProtoReq({
            checkOrgId: new OrganizationCtlProtoCheckOrgIDReq({ id: orgId })
        })).pipe(map((res) => ({ exists: res.checkOrgId.exists })));
    }

    getOrg(orgId: string, unsub$: UnsubListener): Observable<ApiResponse<Organization>> {
        return this.api.orgReqSub(new OrganizationCtlProtoReq({ getOrg: new OrganizationCtlProtoGetOrgReq({ orgId }) }), unsub$)
            .pipe(map((res) => responseOf(res.type, partialOrgOf(res.data.getOrg.org))));
    }

    updateOrg(org: PartialWithUuid<Organization>): Observable<Ok> {
        return this.api.orgReq(new OrganizationCtlProtoReq({ updateOrg: new OrganizationCtlProtoUpdateOrgReq({ org: orgProtoOf(org) }) }));
    }

    // TODO
    // deleteOrg(orgUuid: string): Observable<Ok> {
    //     return this.api.orgReq(new OrganizationCtlProtoReq({ deleteOrg: new OrganizationCtlProtoDeleteOrgReq({ orgUuid: stringToBytes(orgUuid) }) }))
    // }

    getMember(userId: string, orgUuid: string, unsub$: UnsubListener): Observable<ApiResponse<OrgMember>> {
        return this.api.orgReqSub(new OrganizationCtlProtoReq(
            { getMember: new OrganizationCtlProtoGetMemberReq({ userId: userId, orgUuid: stringToBytes(orgUuid) }) }
        ), unsub$).pipe(
            map((res) => responseOf(res.type, partialOrgMemberOf(res.data.getMember.member)))
        );
    }

    listClusters(orgUuid: string, params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<Cluster>> {
        return this.api.orgReqSub(new OrganizationCtlProtoReq(
            { listClusters: new OrganizationCtlProtoListClustersReq({ orgUuid: stringToBytes(orgUuid), params: parametersProtoOf(params) }) }
        ), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listClusters.clusters.map(partialClusterOf))));
    }

    listProjects(orgUuid: string, params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<Project>> {
        return this.api.orgReqSub(new OrganizationCtlProtoReq(
            { listProjects: new OrganizationCtlProtoListProjectsReq({ orgUuid: stringToBytes(orgUuid), params: parametersProtoOf(params) }) }
        ), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listProjects.projects.map(partialProjectOf))));
    }

    listTeams(orgUuid: string, params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<Team>> {
        return this.api.orgReqSub(new OrganizationCtlProtoReq(
            { listTeams: new OrganizationCtlProtoListTeamsReq({ orgUuid: stringToBytes(orgUuid), params: parametersProtoOf(params) }) }
        ), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listTeams.teams.map(partialTeamOf))));
    }

    listMembers(orgUuid: string, params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<OrgMember>> {
        return this.api.orgReqSub(new OrganizationCtlProtoReq(
            { listMembers: new OrganizationCtlProtoListMembersReq({ orgUuid: stringToBytes(orgUuid), params: parametersProtoOf(params) }) }
        ), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listMembers.members.map(partialOrgMemberOf))));
    }

    listInvitations(orgUuid: string, params: Parameters, unsub$: UnsubListener): Observable<ApiListResponse<Invitation>> {
        return this.api.orgReqSub(new OrganizationCtlProtoReq(
            { listInvitations: new OrganizationCtlProtoListInvitationsReq({ orgUuid: stringToBytes(orgUuid), params: parametersProtoOf(params) }) }
        ), unsub$).pipe(map((res) => responseListOf(res.type, res.data.listInvitations.invitations.map(partialInvitationOf))));
    }

    inviteMember(props: {
        orgUuid: string, userEmail: string, accessLevel: AccessLevel,
        teams: { uuid: string, accessLevel: AccessLevel }[],
        projects: { uuid: string, accessLevel: AccessLevel }[],
    }): Observable<Ok> {
        return this.api.orgReq(new OrganizationCtlProtoReq({
            inviteMember: new OrganizationCtlProtoInviteMemberReq({
                orgUuid: stringToBytes(props.orgUuid),
                userEmail: props.userEmail,
                accessLevel: accessLevelProtoOf(props.accessLevel),
                teamAccess: props.teams.map(x => new OrganizationCtlProtoInviteMemberTeamAccess({
                    teamUuid: stringToBytes(x.uuid),
                    accessLevel: accessLevelProtoOf(x.accessLevel),
                })),
                projectAccess: props.projects.map(x => new OrganizationCtlProtoInviteMemberProjectAccess({
                    projectUuid: stringToBytes(x.uuid),
                    accessLevel: accessLevelProtoOf(x.accessLevel),
                })),
            })
        }));
    }

    resendInvitation(invitationUuid: string): Observable<Ok> {
        return this.api.orgReq(new OrganizationCtlProtoReq({
            resendInvitation: new OrganizationCtlProtoResendInvitationReq({ invitationUuid: stringToBytes(invitationUuid) })
        }));
    }

    revokeInvitation(invitationUuid: string): Observable<Ok> {
        return this.api.orgReq(new OrganizationCtlProtoReq({
            revokeInvitation: new OrganizationCtlProtoRevokeInvitationReq({ invitationUuid: stringToBytes(invitationUuid) })
        }));
    }

    removeMember(props: { orgUuid: string, userUuid: string }): Observable<Ok> {
        return this.api.orgReq(new OrganizationCtlProtoReq({
            removeMember: new OrganizationCtlProtoRemoveMemberReq(
                { orgUuid: stringToBytes(props.orgUuid), userUuid: stringToBytes(props.userUuid) }
            )
        }));
    }

    setMemberAccessLevel(props: { orgUuid: string, userUuid: string, accessLevel: AccessLevel }): Observable<Ok> {
        return this.api.orgReq(new OrganizationCtlProtoReq({
            setMemberAccessLevel: new OrganizationCtlProtoSetMemberAccessLevelReq({
                orgUuid: stringToBytes(props.orgUuid),
                userUuid: stringToBytes(props.userUuid),
                accessLevel: accessLevelProtoOf(props.accessLevel),
            })
        }));
    }

    getFreeClusterCount(orgUuid: string): Observable<number> {
        return this.api.orgReqRes(new OrganizationCtlProtoReq({
            getFreeClusterCount: new OrganizationCtlProtoGetFreeClusterCountReq({ orgUuid: stringToBytes(orgUuid) })
        })).pipe(map((res) => res.getFreeClusterCount.count));
    }

    getPayableClusterOptions(orgUuid: string): Observable<ProviderOptions[]> {
        return this.api.orgReqRes(new OrganizationCtlProtoReq({
            getPayableClusterOptions: new OrganizationCtlProtoGetPayableClusterOptionsReq({ orgUuid: stringToBytes(orgUuid) })
        })).pipe(map(res => res.getPayableClusterOptions.providers.map(providerOptionsOf)));
    }
}
