<tp-page-scaffold [pageAvailability]="availability$ | async">
  <div class="action-bar">
    @if (breadcrumb$ | async; as breadcrumb) {
      <tp-breadcrumb [items]="breadcrumb.items" [url]="breadcrumb.url"/>
    }
    <div class="flex-spacer"></div>
    <tp-button
      [matTooltip]="(cannotSuspendReason$ | async) || ''" buttonStyle="secondary" buttonId="suspendCluster"
      [enabled]="(suspendEnabled$ | async) || false" (click)="openSuspendResumeModal('suspend')"
    >
      <fa-icon [icon]="['fal', 'circle-pause']"/> Suspend
    </tp-button>
    <tp-button
      [matTooltip]="(cannotResumeReason$ | async) || ''" buttonStyle="secondary" buttonId="resumeCluster"
      [enabled]="(resumeEnabled$ | async) || false" (click)="openSuspendResumeModal('resume')"
    >
      <fa-icon [icon]="['fal', 'circle-play']"/> Resume
    </tp-button>
  </div>
  @if (cluster$ | async; as cluster) {
    <article>
      <section>
        <div class="section-title-row">
          <h2>{{ cluster.id }}</h2>
          <tp-button
            [matTooltip]="(cannotConnectReason$ | async) || ''" buttonStyle="primary-solid green" buttonId="connectToCluster"
            [enabled]="(connectEnabled$ | async) || false" (click)="openConnectDialog()"
          >
            <fa-icon [icon]="['fas', 'bolt']"/>Connect
          </tp-button>
          <tp-button
            (click)="openEditModal()" buttonStyle="secondary stroke"
            [enabled]="(hasWriteAccess$ | async) || false" buttonId="editCluster"
          >
            <fa-icon [icon]="['fal', 'pen-to-square']"/> Edit
          </tp-button>
        </div>
      </section>

      <section>
        <h3 class="section-title-row">Servers</h3>

        @if (serversReady$ | async) {
          <table mat-table [dataSource]="servers$">
            <ng-container matColumnDef="address">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
              <td mat-cell *matCellDef="let server"><tp-table-copyable-text-cell [text]="server.address || 'Loading...'"/></td>
            </ng-container>
            <ng-container matColumnDef="serverStatus">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
              <td mat-cell *matCellDef="let server">{{ server.status | serverStatus }}</td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell *matCellDef="let server"><tp-table-status-cell [status]="serverIcon(server, dialogOpen)"/></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="serverColumns"></tr>
            <tr mat-row class="table-row-clickable" *matRowDef="let server; columns: serverColumns" (click)="handleServerTableRowClick(server)"></tr>
          </table>
        } @else {
          <tp-spinner/>
        }
      </section>

      <section>
        <tp-properties-table title="Cluster Details">
          <tp-properties-table-row key="Cluster Name">{{ cluster.id }}</tp-properties-table-row>
          <tp-properties-table-row key="Project">
            <p><a [routerLink]="cluster.project | projectDetailsPath">{{ cluster.project.id }}</a></p>
          </tp-properties-table-row>
          @if (runtime$ | async; as runtime) {
            <tp-properties-table-row key="Availability" [loading]="runtime.availableServerCount < runtime.totalServers">
              {{ availabilityText$ | async }}
            </tp-properties-table-row>
          } @else {
            <tp-properties-table-row key="Availability" [loading]="true"></tp-properties-table-row>
          }
          <tp-properties-table-row key="Provider">{{ cluster.provider.name }}</tp-properties-table-row>
          <tp-properties-table-row key="Region">{{ cluster.region | region:"long" }}</tp-properties-table-row>
          <tp-properties-table-row key="Machine Type">{{ cluster.machineType | machineType }}</tp-properties-table-row>
          <tp-properties-table-row key="Storage Capacity">{{ cluster.storageGB | storageSize }}</tp-properties-table-row>
          <tp-properties-table-row key="Storage Type">{{ cluster.storageType | storageType }}</tp-properties-table-row>
          <tp-properties-table-row key="TypeDB Version">
            {{ cluster.typeDBVersion.version }}
            @if (typeDBUpgradeAvailable$ | async) {
              <div class="flex-spacer"></div>
              <tp-button
                buttonStyle="primary-outline green stroke" [height]="32" [enabled]="true"
                buttonId="updateToLatestTypeDB" (click)="openUpdateTypeDBModal()"
              >
                Update to {{ latestTypeDBVersion$ | async }}
              </tp-button>
            }
          </tp-properties-table-row>
          <tp-properties-table-row key="Status">{{ cluster.status | clusterStatus }}</tp-properties-table-row>
          <tp-properties-table-row key="Creation Date">{{ cluster.createdAt | datetime }}</tp-properties-table-row>
        </tp-properties-table>
      </section>

      <tp-delete-resource-section
        title="Destroy this cluster"
        [display]="{
            enabled: (destroyEnabled$ | async) || false,
            enabledText: 'Once you destroy a cluster, all of its data will be permanently erased. Please be certain.',
            disabledText: (cannotDestroyReason$ | async) || ''
        }"
        [buttonIcon]="['fal', 'trash-can-xmark']"
        buttonText="Destroy" (buttonPressed)="openDestroyModal()"
      />
    </article>
  }
</tp-page-scaffold>
