/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.21.8
 * source: application/protocol/cluster-controller.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./../../protocol/concept";
import * as pb_1 from "google-protobuf";
export class ClusterCtlProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ClusterCtlProto {
        const message = new ClusterCtlProto({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProto {
        return ClusterCtlProto.deserialize(bytes);
    }
}
export class ClusterCtlProtoReq extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]];
    constructor(data?: any[] | ({} & (({
        getAccessLevels?: ClusterCtlProtoGetAccessLevelsReq;
        getLatestTypedb?: never;
        getClusterOptions?: never;
        getClusterPrice?: never;
        getClusterPresetPrice?: never;
        deployCluster?: never;
        getCluster?: never;
        updateCluster?: never;
        updateToLatestTypedb?: never;
        suspendCluster?: never;
        resumeCluster?: never;
        destroyCluster?: never;
        listServers?: never;
        listServerLogs?: never;
        setupCluster?: never;
        createDatabase?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: ClusterCtlProtoGetLatestTypeDBReq;
        getClusterOptions?: never;
        getClusterPrice?: never;
        getClusterPresetPrice?: never;
        deployCluster?: never;
        getCluster?: never;
        updateCluster?: never;
        updateToLatestTypedb?: never;
        suspendCluster?: never;
        resumeCluster?: never;
        destroyCluster?: never;
        listServers?: never;
        listServerLogs?: never;
        setupCluster?: never;
        createDatabase?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getClusterOptions?: ClusterCtlProtoGetClusterOptionsReq;
        getClusterPrice?: never;
        getClusterPresetPrice?: never;
        deployCluster?: never;
        getCluster?: never;
        updateCluster?: never;
        updateToLatestTypedb?: never;
        suspendCluster?: never;
        resumeCluster?: never;
        destroyCluster?: never;
        listServers?: never;
        listServerLogs?: never;
        setupCluster?: never;
        createDatabase?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getClusterOptions?: never;
        getClusterPrice?: ClusterCtlProtoGetClusterPriceReq;
        getClusterPresetPrice?: never;
        deployCluster?: never;
        getCluster?: never;
        updateCluster?: never;
        updateToLatestTypedb?: never;
        suspendCluster?: never;
        resumeCluster?: never;
        destroyCluster?: never;
        listServers?: never;
        listServerLogs?: never;
        setupCluster?: never;
        createDatabase?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getClusterOptions?: never;
        getClusterPrice?: never;
        getClusterPresetPrice?: ClusterCtlProtoGetClusterPresetPriceReq;
        deployCluster?: never;
        getCluster?: never;
        updateCluster?: never;
        updateToLatestTypedb?: never;
        suspendCluster?: never;
        resumeCluster?: never;
        destroyCluster?: never;
        listServers?: never;
        listServerLogs?: never;
        setupCluster?: never;
        createDatabase?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getClusterOptions?: never;
        getClusterPrice?: never;
        getClusterPresetPrice?: never;
        deployCluster?: ClusterCtlProtoDeployClusterReq;
        getCluster?: never;
        updateCluster?: never;
        updateToLatestTypedb?: never;
        suspendCluster?: never;
        resumeCluster?: never;
        destroyCluster?: never;
        listServers?: never;
        listServerLogs?: never;
        setupCluster?: never;
        createDatabase?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getClusterOptions?: never;
        getClusterPrice?: never;
        getClusterPresetPrice?: never;
        deployCluster?: never;
        getCluster?: ClusterCtlProtoGetClusterReq;
        updateCluster?: never;
        updateToLatestTypedb?: never;
        suspendCluster?: never;
        resumeCluster?: never;
        destroyCluster?: never;
        listServers?: never;
        listServerLogs?: never;
        setupCluster?: never;
        createDatabase?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getClusterOptions?: never;
        getClusterPrice?: never;
        getClusterPresetPrice?: never;
        deployCluster?: never;
        getCluster?: never;
        updateCluster?: ClusterCtlProtoUpdateClusterReq;
        updateToLatestTypedb?: never;
        suspendCluster?: never;
        resumeCluster?: never;
        destroyCluster?: never;
        listServers?: never;
        listServerLogs?: never;
        setupCluster?: never;
        createDatabase?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getClusterOptions?: never;
        getClusterPrice?: never;
        getClusterPresetPrice?: never;
        deployCluster?: never;
        getCluster?: never;
        updateCluster?: never;
        updateToLatestTypedb?: ClusterCtlProtoUpdateToLatestTypeDBReq;
        suspendCluster?: never;
        resumeCluster?: never;
        destroyCluster?: never;
        listServers?: never;
        listServerLogs?: never;
        setupCluster?: never;
        createDatabase?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getClusterOptions?: never;
        getClusterPrice?: never;
        getClusterPresetPrice?: never;
        deployCluster?: never;
        getCluster?: never;
        updateCluster?: never;
        updateToLatestTypedb?: never;
        suspendCluster?: ClusterCtlProtoSuspendClusterReq;
        resumeCluster?: never;
        destroyCluster?: never;
        listServers?: never;
        listServerLogs?: never;
        setupCluster?: never;
        createDatabase?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getClusterOptions?: never;
        getClusterPrice?: never;
        getClusterPresetPrice?: never;
        deployCluster?: never;
        getCluster?: never;
        updateCluster?: never;
        updateToLatestTypedb?: never;
        suspendCluster?: never;
        resumeCluster?: ClusterCtlProtoResumeClusterReq;
        destroyCluster?: never;
        listServers?: never;
        listServerLogs?: never;
        setupCluster?: never;
        createDatabase?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getClusterOptions?: never;
        getClusterPrice?: never;
        getClusterPresetPrice?: never;
        deployCluster?: never;
        getCluster?: never;
        updateCluster?: never;
        updateToLatestTypedb?: never;
        suspendCluster?: never;
        resumeCluster?: never;
        destroyCluster?: ClusterCtlProtoDestroyClusterReq;
        listServers?: never;
        listServerLogs?: never;
        setupCluster?: never;
        createDatabase?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getClusterOptions?: never;
        getClusterPrice?: never;
        getClusterPresetPrice?: never;
        deployCluster?: never;
        getCluster?: never;
        updateCluster?: never;
        updateToLatestTypedb?: never;
        suspendCluster?: never;
        resumeCluster?: never;
        destroyCluster?: never;
        listServers?: ClusterCtlProtoListServersReq;
        listServerLogs?: never;
        setupCluster?: never;
        createDatabase?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getClusterOptions?: never;
        getClusterPrice?: never;
        getClusterPresetPrice?: never;
        deployCluster?: never;
        getCluster?: never;
        updateCluster?: never;
        updateToLatestTypedb?: never;
        suspendCluster?: never;
        resumeCluster?: never;
        destroyCluster?: never;
        listServers?: never;
        listServerLogs?: ClusterCtlProtoListServerLogsReq;
        setupCluster?: never;
        createDatabase?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getClusterOptions?: never;
        getClusterPrice?: never;
        getClusterPresetPrice?: never;
        deployCluster?: never;
        getCluster?: never;
        updateCluster?: never;
        updateToLatestTypedb?: never;
        suspendCluster?: never;
        resumeCluster?: never;
        destroyCluster?: never;
        listServers?: never;
        listServerLogs?: never;
        setupCluster?: ClusterCtlProtoSetupClusterReq;
        createDatabase?: never;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getClusterOptions?: never;
        getClusterPrice?: never;
        getClusterPresetPrice?: never;
        deployCluster?: never;
        getCluster?: never;
        updateCluster?: never;
        updateToLatestTypedb?: never;
        suspendCluster?: never;
        resumeCluster?: never;
        destroyCluster?: never;
        listServers?: never;
        listServerLogs?: never;
        setupCluster?: never;
        createDatabase?: ClusterCtlProtoCreateDatabaseReq;
        loadSampleDataset?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getClusterOptions?: never;
        getClusterPrice?: never;
        getClusterPresetPrice?: never;
        deployCluster?: never;
        getCluster?: never;
        updateCluster?: never;
        updateToLatestTypedb?: never;
        suspendCluster?: never;
        resumeCluster?: never;
        destroyCluster?: never;
        listServers?: never;
        listServerLogs?: never;
        setupCluster?: never;
        createDatabase?: never;
        loadSampleDataset?: ClusterCtlProtoLoadSampleDatasetReq;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("getAccessLevels" in data && data.getAccessLevels != undefined) {
                this.getAccessLevels = data.getAccessLevels;
            }
            if ("getLatestTypedb" in data && data.getLatestTypedb != undefined) {
                this.getLatestTypedb = data.getLatestTypedb;
            }
            if ("getClusterOptions" in data && data.getClusterOptions != undefined) {
                this.getClusterOptions = data.getClusterOptions;
            }
            if ("getClusterPrice" in data && data.getClusterPrice != undefined) {
                this.getClusterPrice = data.getClusterPrice;
            }
            if ("getClusterPresetPrice" in data && data.getClusterPresetPrice != undefined) {
                this.getClusterPresetPrice = data.getClusterPresetPrice;
            }
            if ("deployCluster" in data && data.deployCluster != undefined) {
                this.deployCluster = data.deployCluster;
            }
            if ("getCluster" in data && data.getCluster != undefined) {
                this.getCluster = data.getCluster;
            }
            if ("updateCluster" in data && data.updateCluster != undefined) {
                this.updateCluster = data.updateCluster;
            }
            if ("updateToLatestTypedb" in data && data.updateToLatestTypedb != undefined) {
                this.updateToLatestTypedb = data.updateToLatestTypedb;
            }
            if ("suspendCluster" in data && data.suspendCluster != undefined) {
                this.suspendCluster = data.suspendCluster;
            }
            if ("resumeCluster" in data && data.resumeCluster != undefined) {
                this.resumeCluster = data.resumeCluster;
            }
            if ("destroyCluster" in data && data.destroyCluster != undefined) {
                this.destroyCluster = data.destroyCluster;
            }
            if ("listServers" in data && data.listServers != undefined) {
                this.listServers = data.listServers;
            }
            if ("listServerLogs" in data && data.listServerLogs != undefined) {
                this.listServerLogs = data.listServerLogs;
            }
            if ("setupCluster" in data && data.setupCluster != undefined) {
                this.setupCluster = data.setupCluster;
            }
            if ("createDatabase" in data && data.createDatabase != undefined) {
                this.createDatabase = data.createDatabase;
            }
            if ("loadSampleDataset" in data && data.loadSampleDataset != undefined) {
                this.loadSampleDataset = data.loadSampleDataset;
            }
        }
    }
    get getAccessLevels() {
        return pb_1.Message.getWrapperField(this, ClusterCtlProtoGetAccessLevelsReq, 1) as ClusterCtlProtoGetAccessLevelsReq;
    }
    set getAccessLevels(value: ClusterCtlProtoGetAccessLevelsReq) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
    }
    get hasGetAccessLevels() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get getLatestTypedb() {
        return pb_1.Message.getWrapperField(this, ClusterCtlProtoGetLatestTypeDBReq, 2) as ClusterCtlProtoGetLatestTypeDBReq;
    }
    set getLatestTypedb(value: ClusterCtlProtoGetLatestTypeDBReq) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get hasGetLatestTypedb() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get getClusterOptions() {
        return pb_1.Message.getWrapperField(this, ClusterCtlProtoGetClusterOptionsReq, 3) as ClusterCtlProtoGetClusterOptionsReq;
    }
    set getClusterOptions(value: ClusterCtlProtoGetClusterOptionsReq) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0], value);
    }
    get hasGetClusterOptions() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get getClusterPrice() {
        return pb_1.Message.getWrapperField(this, ClusterCtlProtoGetClusterPriceReq, 4) as ClusterCtlProtoGetClusterPriceReq;
    }
    set getClusterPrice(value: ClusterCtlProtoGetClusterPriceReq) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
    }
    get hasGetClusterPrice() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get getClusterPresetPrice() {
        return pb_1.Message.getWrapperField(this, ClusterCtlProtoGetClusterPresetPriceReq, 5) as ClusterCtlProtoGetClusterPresetPriceReq;
    }
    set getClusterPresetPrice(value: ClusterCtlProtoGetClusterPresetPriceReq) {
        pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0], value);
    }
    get hasGetClusterPresetPrice() {
        return pb_1.Message.getField(this, 5) != null;
    }
    get deployCluster() {
        return pb_1.Message.getWrapperField(this, ClusterCtlProtoDeployClusterReq, 6) as ClusterCtlProtoDeployClusterReq;
    }
    set deployCluster(value: ClusterCtlProtoDeployClusterReq) {
        pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0], value);
    }
    get hasDeployCluster() {
        return pb_1.Message.getField(this, 6) != null;
    }
    get getCluster() {
        return pb_1.Message.getWrapperField(this, ClusterCtlProtoGetClusterReq, 7) as ClusterCtlProtoGetClusterReq;
    }
    set getCluster(value: ClusterCtlProtoGetClusterReq) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0], value);
    }
    get hasGetCluster() {
        return pb_1.Message.getField(this, 7) != null;
    }
    get updateCluster() {
        return pb_1.Message.getWrapperField(this, ClusterCtlProtoUpdateClusterReq, 8) as ClusterCtlProtoUpdateClusterReq;
    }
    set updateCluster(value: ClusterCtlProtoUpdateClusterReq) {
        pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0], value);
    }
    get hasUpdateCluster() {
        return pb_1.Message.getField(this, 8) != null;
    }
    get updateToLatestTypedb() {
        return pb_1.Message.getWrapperField(this, ClusterCtlProtoUpdateToLatestTypeDBReq, 9) as ClusterCtlProtoUpdateToLatestTypeDBReq;
    }
    set updateToLatestTypedb(value: ClusterCtlProtoUpdateToLatestTypeDBReq) {
        pb_1.Message.setOneofWrapperField(this, 9, this.#one_of_decls[0], value);
    }
    get hasUpdateToLatestTypedb() {
        return pb_1.Message.getField(this, 9) != null;
    }
    get suspendCluster() {
        return pb_1.Message.getWrapperField(this, ClusterCtlProtoSuspendClusterReq, 10) as ClusterCtlProtoSuspendClusterReq;
    }
    set suspendCluster(value: ClusterCtlProtoSuspendClusterReq) {
        pb_1.Message.setOneofWrapperField(this, 10, this.#one_of_decls[0], value);
    }
    get hasSuspendCluster() {
        return pb_1.Message.getField(this, 10) != null;
    }
    get resumeCluster() {
        return pb_1.Message.getWrapperField(this, ClusterCtlProtoResumeClusterReq, 11) as ClusterCtlProtoResumeClusterReq;
    }
    set resumeCluster(value: ClusterCtlProtoResumeClusterReq) {
        pb_1.Message.setOneofWrapperField(this, 11, this.#one_of_decls[0], value);
    }
    get hasResumeCluster() {
        return pb_1.Message.getField(this, 11) != null;
    }
    get destroyCluster() {
        return pb_1.Message.getWrapperField(this, ClusterCtlProtoDestroyClusterReq, 12) as ClusterCtlProtoDestroyClusterReq;
    }
    set destroyCluster(value: ClusterCtlProtoDestroyClusterReq) {
        pb_1.Message.setOneofWrapperField(this, 12, this.#one_of_decls[0], value);
    }
    get hasDestroyCluster() {
        return pb_1.Message.getField(this, 12) != null;
    }
    get listServers() {
        return pb_1.Message.getWrapperField(this, ClusterCtlProtoListServersReq, 13) as ClusterCtlProtoListServersReq;
    }
    set listServers(value: ClusterCtlProtoListServersReq) {
        pb_1.Message.setOneofWrapperField(this, 13, this.#one_of_decls[0], value);
    }
    get hasListServers() {
        return pb_1.Message.getField(this, 13) != null;
    }
    get listServerLogs() {
        return pb_1.Message.getWrapperField(this, ClusterCtlProtoListServerLogsReq, 14) as ClusterCtlProtoListServerLogsReq;
    }
    set listServerLogs(value: ClusterCtlProtoListServerLogsReq) {
        pb_1.Message.setOneofWrapperField(this, 14, this.#one_of_decls[0], value);
    }
    get hasListServerLogs() {
        return pb_1.Message.getField(this, 14) != null;
    }
    get setupCluster() {
        return pb_1.Message.getWrapperField(this, ClusterCtlProtoSetupClusterReq, 15) as ClusterCtlProtoSetupClusterReq;
    }
    set setupCluster(value: ClusterCtlProtoSetupClusterReq) {
        pb_1.Message.setOneofWrapperField(this, 15, this.#one_of_decls[0], value);
    }
    get hasSetupCluster() {
        return pb_1.Message.getField(this, 15) != null;
    }
    get createDatabase() {
        return pb_1.Message.getWrapperField(this, ClusterCtlProtoCreateDatabaseReq, 16) as ClusterCtlProtoCreateDatabaseReq;
    }
    set createDatabase(value: ClusterCtlProtoCreateDatabaseReq) {
        pb_1.Message.setOneofWrapperField(this, 16, this.#one_of_decls[0], value);
    }
    get hasCreateDatabase() {
        return pb_1.Message.getField(this, 16) != null;
    }
    get loadSampleDataset() {
        return pb_1.Message.getWrapperField(this, ClusterCtlProtoLoadSampleDatasetReq, 17) as ClusterCtlProtoLoadSampleDatasetReq;
    }
    set loadSampleDataset(value: ClusterCtlProtoLoadSampleDatasetReq) {
        pb_1.Message.setOneofWrapperField(this, 17, this.#one_of_decls[0], value);
    }
    get hasLoadSampleDataset() {
        return pb_1.Message.getField(this, 17) != null;
    }
    get req() {
        const cases: {
            [index: number]: "none" | "getAccessLevels" | "getLatestTypedb" | "getClusterOptions" | "getClusterPrice" | "getClusterPresetPrice" | "deployCluster" | "getCluster" | "updateCluster" | "updateToLatestTypedb" | "suspendCluster" | "resumeCluster" | "destroyCluster" | "listServers" | "listServerLogs" | "setupCluster" | "createDatabase" | "loadSampleDataset";
        } = {
            0: "none",
            1: "getAccessLevels",
            2: "getLatestTypedb",
            3: "getClusterOptions",
            4: "getClusterPrice",
            5: "getClusterPresetPrice",
            6: "deployCluster",
            7: "getCluster",
            8: "updateCluster",
            9: "updateToLatestTypedb",
            10: "suspendCluster",
            11: "resumeCluster",
            12: "destroyCluster",
            13: "listServers",
            14: "listServerLogs",
            15: "setupCluster",
            16: "createDatabase",
            17: "loadSampleDataset"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17])];
    }
    static fromObject(data: {
        getAccessLevels?: ReturnType<typeof ClusterCtlProtoGetAccessLevelsReq.prototype.toObject>;
        getLatestTypedb?: ReturnType<typeof ClusterCtlProtoGetLatestTypeDBReq.prototype.toObject>;
        getClusterOptions?: ReturnType<typeof ClusterCtlProtoGetClusterOptionsReq.prototype.toObject>;
        getClusterPrice?: ReturnType<typeof ClusterCtlProtoGetClusterPriceReq.prototype.toObject>;
        getClusterPresetPrice?: ReturnType<typeof ClusterCtlProtoGetClusterPresetPriceReq.prototype.toObject>;
        deployCluster?: ReturnType<typeof ClusterCtlProtoDeployClusterReq.prototype.toObject>;
        getCluster?: ReturnType<typeof ClusterCtlProtoGetClusterReq.prototype.toObject>;
        updateCluster?: ReturnType<typeof ClusterCtlProtoUpdateClusterReq.prototype.toObject>;
        updateToLatestTypedb?: ReturnType<typeof ClusterCtlProtoUpdateToLatestTypeDBReq.prototype.toObject>;
        suspendCluster?: ReturnType<typeof ClusterCtlProtoSuspendClusterReq.prototype.toObject>;
        resumeCluster?: ReturnType<typeof ClusterCtlProtoResumeClusterReq.prototype.toObject>;
        destroyCluster?: ReturnType<typeof ClusterCtlProtoDestroyClusterReq.prototype.toObject>;
        listServers?: ReturnType<typeof ClusterCtlProtoListServersReq.prototype.toObject>;
        listServerLogs?: ReturnType<typeof ClusterCtlProtoListServerLogsReq.prototype.toObject>;
        setupCluster?: ReturnType<typeof ClusterCtlProtoSetupClusterReq.prototype.toObject>;
        createDatabase?: ReturnType<typeof ClusterCtlProtoCreateDatabaseReq.prototype.toObject>;
        loadSampleDataset?: ReturnType<typeof ClusterCtlProtoLoadSampleDatasetReq.prototype.toObject>;
    }): ClusterCtlProtoReq {
        const message = new ClusterCtlProtoReq({});
        if (data.getAccessLevels != null) {
            message.getAccessLevels = ClusterCtlProtoGetAccessLevelsReq.fromObject(data.getAccessLevels);
        }
        if (data.getLatestTypedb != null) {
            message.getLatestTypedb = ClusterCtlProtoGetLatestTypeDBReq.fromObject(data.getLatestTypedb);
        }
        if (data.getClusterOptions != null) {
            message.getClusterOptions = ClusterCtlProtoGetClusterOptionsReq.fromObject(data.getClusterOptions);
        }
        if (data.getClusterPrice != null) {
            message.getClusterPrice = ClusterCtlProtoGetClusterPriceReq.fromObject(data.getClusterPrice);
        }
        if (data.getClusterPresetPrice != null) {
            message.getClusterPresetPrice = ClusterCtlProtoGetClusterPresetPriceReq.fromObject(data.getClusterPresetPrice);
        }
        if (data.deployCluster != null) {
            message.deployCluster = ClusterCtlProtoDeployClusterReq.fromObject(data.deployCluster);
        }
        if (data.getCluster != null) {
            message.getCluster = ClusterCtlProtoGetClusterReq.fromObject(data.getCluster);
        }
        if (data.updateCluster != null) {
            message.updateCluster = ClusterCtlProtoUpdateClusterReq.fromObject(data.updateCluster);
        }
        if (data.updateToLatestTypedb != null) {
            message.updateToLatestTypedb = ClusterCtlProtoUpdateToLatestTypeDBReq.fromObject(data.updateToLatestTypedb);
        }
        if (data.suspendCluster != null) {
            message.suspendCluster = ClusterCtlProtoSuspendClusterReq.fromObject(data.suspendCluster);
        }
        if (data.resumeCluster != null) {
            message.resumeCluster = ClusterCtlProtoResumeClusterReq.fromObject(data.resumeCluster);
        }
        if (data.destroyCluster != null) {
            message.destroyCluster = ClusterCtlProtoDestroyClusterReq.fromObject(data.destroyCluster);
        }
        if (data.listServers != null) {
            message.listServers = ClusterCtlProtoListServersReq.fromObject(data.listServers);
        }
        if (data.listServerLogs != null) {
            message.listServerLogs = ClusterCtlProtoListServerLogsReq.fromObject(data.listServerLogs);
        }
        if (data.setupCluster != null) {
            message.setupCluster = ClusterCtlProtoSetupClusterReq.fromObject(data.setupCluster);
        }
        if (data.createDatabase != null) {
            message.createDatabase = ClusterCtlProtoCreateDatabaseReq.fromObject(data.createDatabase);
        }
        if (data.loadSampleDataset != null) {
            message.loadSampleDataset = ClusterCtlProtoLoadSampleDatasetReq.fromObject(data.loadSampleDataset);
        }
        return message;
    }
    toObject() {
        const data: {
            getAccessLevels?: ReturnType<typeof ClusterCtlProtoGetAccessLevelsReq.prototype.toObject>;
            getLatestTypedb?: ReturnType<typeof ClusterCtlProtoGetLatestTypeDBReq.prototype.toObject>;
            getClusterOptions?: ReturnType<typeof ClusterCtlProtoGetClusterOptionsReq.prototype.toObject>;
            getClusterPrice?: ReturnType<typeof ClusterCtlProtoGetClusterPriceReq.prototype.toObject>;
            getClusterPresetPrice?: ReturnType<typeof ClusterCtlProtoGetClusterPresetPriceReq.prototype.toObject>;
            deployCluster?: ReturnType<typeof ClusterCtlProtoDeployClusterReq.prototype.toObject>;
            getCluster?: ReturnType<typeof ClusterCtlProtoGetClusterReq.prototype.toObject>;
            updateCluster?: ReturnType<typeof ClusterCtlProtoUpdateClusterReq.prototype.toObject>;
            updateToLatestTypedb?: ReturnType<typeof ClusterCtlProtoUpdateToLatestTypeDBReq.prototype.toObject>;
            suspendCluster?: ReturnType<typeof ClusterCtlProtoSuspendClusterReq.prototype.toObject>;
            resumeCluster?: ReturnType<typeof ClusterCtlProtoResumeClusterReq.prototype.toObject>;
            destroyCluster?: ReturnType<typeof ClusterCtlProtoDestroyClusterReq.prototype.toObject>;
            listServers?: ReturnType<typeof ClusterCtlProtoListServersReq.prototype.toObject>;
            listServerLogs?: ReturnType<typeof ClusterCtlProtoListServerLogsReq.prototype.toObject>;
            setupCluster?: ReturnType<typeof ClusterCtlProtoSetupClusterReq.prototype.toObject>;
            createDatabase?: ReturnType<typeof ClusterCtlProtoCreateDatabaseReq.prototype.toObject>;
            loadSampleDataset?: ReturnType<typeof ClusterCtlProtoLoadSampleDatasetReq.prototype.toObject>;
        } = {};
        if (this.getAccessLevels != null) {
            data.getAccessLevels = this.getAccessLevels.toObject();
        }
        if (this.getLatestTypedb != null) {
            data.getLatestTypedb = this.getLatestTypedb.toObject();
        }
        if (this.getClusterOptions != null) {
            data.getClusterOptions = this.getClusterOptions.toObject();
        }
        if (this.getClusterPrice != null) {
            data.getClusterPrice = this.getClusterPrice.toObject();
        }
        if (this.getClusterPresetPrice != null) {
            data.getClusterPresetPrice = this.getClusterPresetPrice.toObject();
        }
        if (this.deployCluster != null) {
            data.deployCluster = this.deployCluster.toObject();
        }
        if (this.getCluster != null) {
            data.getCluster = this.getCluster.toObject();
        }
        if (this.updateCluster != null) {
            data.updateCluster = this.updateCluster.toObject();
        }
        if (this.updateToLatestTypedb != null) {
            data.updateToLatestTypedb = this.updateToLatestTypedb.toObject();
        }
        if (this.suspendCluster != null) {
            data.suspendCluster = this.suspendCluster.toObject();
        }
        if (this.resumeCluster != null) {
            data.resumeCluster = this.resumeCluster.toObject();
        }
        if (this.destroyCluster != null) {
            data.destroyCluster = this.destroyCluster.toObject();
        }
        if (this.listServers != null) {
            data.listServers = this.listServers.toObject();
        }
        if (this.listServerLogs != null) {
            data.listServerLogs = this.listServerLogs.toObject();
        }
        if (this.setupCluster != null) {
            data.setupCluster = this.setupCluster.toObject();
        }
        if (this.createDatabase != null) {
            data.createDatabase = this.createDatabase.toObject();
        }
        if (this.loadSampleDataset != null) {
            data.loadSampleDataset = this.loadSampleDataset.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasGetAccessLevels)
            writer.writeMessage(1, this.getAccessLevels, () => this.getAccessLevels.serialize(writer));
        if (this.hasGetLatestTypedb)
            writer.writeMessage(2, this.getLatestTypedb, () => this.getLatestTypedb.serialize(writer));
        if (this.hasGetClusterOptions)
            writer.writeMessage(3, this.getClusterOptions, () => this.getClusterOptions.serialize(writer));
        if (this.hasGetClusterPrice)
            writer.writeMessage(4, this.getClusterPrice, () => this.getClusterPrice.serialize(writer));
        if (this.hasGetClusterPresetPrice)
            writer.writeMessage(5, this.getClusterPresetPrice, () => this.getClusterPresetPrice.serialize(writer));
        if (this.hasDeployCluster)
            writer.writeMessage(6, this.deployCluster, () => this.deployCluster.serialize(writer));
        if (this.hasGetCluster)
            writer.writeMessage(7, this.getCluster, () => this.getCluster.serialize(writer));
        if (this.hasUpdateCluster)
            writer.writeMessage(8, this.updateCluster, () => this.updateCluster.serialize(writer));
        if (this.hasUpdateToLatestTypedb)
            writer.writeMessage(9, this.updateToLatestTypedb, () => this.updateToLatestTypedb.serialize(writer));
        if (this.hasSuspendCluster)
            writer.writeMessage(10, this.suspendCluster, () => this.suspendCluster.serialize(writer));
        if (this.hasResumeCluster)
            writer.writeMessage(11, this.resumeCluster, () => this.resumeCluster.serialize(writer));
        if (this.hasDestroyCluster)
            writer.writeMessage(12, this.destroyCluster, () => this.destroyCluster.serialize(writer));
        if (this.hasListServers)
            writer.writeMessage(13, this.listServers, () => this.listServers.serialize(writer));
        if (this.hasListServerLogs)
            writer.writeMessage(14, this.listServerLogs, () => this.listServerLogs.serialize(writer));
        if (this.hasSetupCluster)
            writer.writeMessage(15, this.setupCluster, () => this.setupCluster.serialize(writer));
        if (this.hasCreateDatabase)
            writer.writeMessage(16, this.createDatabase, () => this.createDatabase.serialize(writer));
        if (this.hasLoadSampleDataset)
            writer.writeMessage(17, this.loadSampleDataset, () => this.loadSampleDataset.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.getAccessLevels, () => message.getAccessLevels = ClusterCtlProtoGetAccessLevelsReq.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.getLatestTypedb, () => message.getLatestTypedb = ClusterCtlProtoGetLatestTypeDBReq.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.getClusterOptions, () => message.getClusterOptions = ClusterCtlProtoGetClusterOptionsReq.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.getClusterPrice, () => message.getClusterPrice = ClusterCtlProtoGetClusterPriceReq.deserialize(reader));
                    break;
                case 5:
                    reader.readMessage(message.getClusterPresetPrice, () => message.getClusterPresetPrice = ClusterCtlProtoGetClusterPresetPriceReq.deserialize(reader));
                    break;
                case 6:
                    reader.readMessage(message.deployCluster, () => message.deployCluster = ClusterCtlProtoDeployClusterReq.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.getCluster, () => message.getCluster = ClusterCtlProtoGetClusterReq.deserialize(reader));
                    break;
                case 8:
                    reader.readMessage(message.updateCluster, () => message.updateCluster = ClusterCtlProtoUpdateClusterReq.deserialize(reader));
                    break;
                case 9:
                    reader.readMessage(message.updateToLatestTypedb, () => message.updateToLatestTypedb = ClusterCtlProtoUpdateToLatestTypeDBReq.deserialize(reader));
                    break;
                case 10:
                    reader.readMessage(message.suspendCluster, () => message.suspendCluster = ClusterCtlProtoSuspendClusterReq.deserialize(reader));
                    break;
                case 11:
                    reader.readMessage(message.resumeCluster, () => message.resumeCluster = ClusterCtlProtoResumeClusterReq.deserialize(reader));
                    break;
                case 12:
                    reader.readMessage(message.destroyCluster, () => message.destroyCluster = ClusterCtlProtoDestroyClusterReq.deserialize(reader));
                    break;
                case 13:
                    reader.readMessage(message.listServers, () => message.listServers = ClusterCtlProtoListServersReq.deserialize(reader));
                    break;
                case 14:
                    reader.readMessage(message.listServerLogs, () => message.listServerLogs = ClusterCtlProtoListServerLogsReq.deserialize(reader));
                    break;
                case 15:
                    reader.readMessage(message.setupCluster, () => message.setupCluster = ClusterCtlProtoSetupClusterReq.deserialize(reader));
                    break;
                case 16:
                    reader.readMessage(message.createDatabase, () => message.createDatabase = ClusterCtlProtoCreateDatabaseReq.deserialize(reader));
                    break;
                case 17:
                    reader.readMessage(message.loadSampleDataset, () => message.loadSampleDataset = ClusterCtlProtoLoadSampleDatasetReq.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoReq {
        return ClusterCtlProtoReq.deserialize(bytes);
    }
}
export class ClusterCtlProtoRes extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3, 4, 5, 6, 7, 8]];
    constructor(data?: any[] | ({} & (({
        getAccessLevels?: ClusterCtlProtoGetAccessLevelsRes;
        getLatestTypedb?: never;
        getClusterOptions?: never;
        getClusterPrice?: never;
        getClusterPresetPrice?: never;
        getCluster?: never;
        listServers?: never;
        listServerLogs?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: ClusterCtlProtoGetLatestTypeDBRes;
        getClusterOptions?: never;
        getClusterPrice?: never;
        getClusterPresetPrice?: never;
        getCluster?: never;
        listServers?: never;
        listServerLogs?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getClusterOptions?: ClusterCtlProtoGetClusterOptionsRes;
        getClusterPrice?: never;
        getClusterPresetPrice?: never;
        getCluster?: never;
        listServers?: never;
        listServerLogs?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getClusterOptions?: never;
        getClusterPrice?: ClusterCtlProtoGetClusterPriceRes;
        getClusterPresetPrice?: never;
        getCluster?: never;
        listServers?: never;
        listServerLogs?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getClusterOptions?: never;
        getClusterPrice?: never;
        getClusterPresetPrice?: ClusterCtlProtoGetClusterPresetPriceRes;
        getCluster?: never;
        listServers?: never;
        listServerLogs?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getClusterOptions?: never;
        getClusterPrice?: never;
        getClusterPresetPrice?: never;
        getCluster?: ClusterCtlProtoGetClusterRes;
        listServers?: never;
        listServerLogs?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getClusterOptions?: never;
        getClusterPrice?: never;
        getClusterPresetPrice?: never;
        getCluster?: never;
        listServers?: ClusterCtlProtoListServersRes;
        listServerLogs?: never;
    } | {
        getAccessLevels?: never;
        getLatestTypedb?: never;
        getClusterOptions?: never;
        getClusterPrice?: never;
        getClusterPresetPrice?: never;
        getCluster?: never;
        listServers?: never;
        listServerLogs?: ClusterCtlProtoListServerLogsRes;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("getAccessLevels" in data && data.getAccessLevels != undefined) {
                this.getAccessLevels = data.getAccessLevels;
            }
            if ("getLatestTypedb" in data && data.getLatestTypedb != undefined) {
                this.getLatestTypedb = data.getLatestTypedb;
            }
            if ("getClusterOptions" in data && data.getClusterOptions != undefined) {
                this.getClusterOptions = data.getClusterOptions;
            }
            if ("getClusterPrice" in data && data.getClusterPrice != undefined) {
                this.getClusterPrice = data.getClusterPrice;
            }
            if ("getClusterPresetPrice" in data && data.getClusterPresetPrice != undefined) {
                this.getClusterPresetPrice = data.getClusterPresetPrice;
            }
            if ("getCluster" in data && data.getCluster != undefined) {
                this.getCluster = data.getCluster;
            }
            if ("listServers" in data && data.listServers != undefined) {
                this.listServers = data.listServers;
            }
            if ("listServerLogs" in data && data.listServerLogs != undefined) {
                this.listServerLogs = data.listServerLogs;
            }
        }
    }
    get getAccessLevels() {
        return pb_1.Message.getWrapperField(this, ClusterCtlProtoGetAccessLevelsRes, 1) as ClusterCtlProtoGetAccessLevelsRes;
    }
    set getAccessLevels(value: ClusterCtlProtoGetAccessLevelsRes) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
    }
    get hasGetAccessLevels() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get getLatestTypedb() {
        return pb_1.Message.getWrapperField(this, ClusterCtlProtoGetLatestTypeDBRes, 2) as ClusterCtlProtoGetLatestTypeDBRes;
    }
    set getLatestTypedb(value: ClusterCtlProtoGetLatestTypeDBRes) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get hasGetLatestTypedb() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get getClusterOptions() {
        return pb_1.Message.getWrapperField(this, ClusterCtlProtoGetClusterOptionsRes, 3) as ClusterCtlProtoGetClusterOptionsRes;
    }
    set getClusterOptions(value: ClusterCtlProtoGetClusterOptionsRes) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0], value);
    }
    get hasGetClusterOptions() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get getClusterPrice() {
        return pb_1.Message.getWrapperField(this, ClusterCtlProtoGetClusterPriceRes, 4) as ClusterCtlProtoGetClusterPriceRes;
    }
    set getClusterPrice(value: ClusterCtlProtoGetClusterPriceRes) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
    }
    get hasGetClusterPrice() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get getClusterPresetPrice() {
        return pb_1.Message.getWrapperField(this, ClusterCtlProtoGetClusterPresetPriceRes, 5) as ClusterCtlProtoGetClusterPresetPriceRes;
    }
    set getClusterPresetPrice(value: ClusterCtlProtoGetClusterPresetPriceRes) {
        pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0], value);
    }
    get hasGetClusterPresetPrice() {
        return pb_1.Message.getField(this, 5) != null;
    }
    get getCluster() {
        return pb_1.Message.getWrapperField(this, ClusterCtlProtoGetClusterRes, 6) as ClusterCtlProtoGetClusterRes;
    }
    set getCluster(value: ClusterCtlProtoGetClusterRes) {
        pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0], value);
    }
    get hasGetCluster() {
        return pb_1.Message.getField(this, 6) != null;
    }
    get listServers() {
        return pb_1.Message.getWrapperField(this, ClusterCtlProtoListServersRes, 7) as ClusterCtlProtoListServersRes;
    }
    set listServers(value: ClusterCtlProtoListServersRes) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0], value);
    }
    get hasListServers() {
        return pb_1.Message.getField(this, 7) != null;
    }
    get listServerLogs() {
        return pb_1.Message.getWrapperField(this, ClusterCtlProtoListServerLogsRes, 8) as ClusterCtlProtoListServerLogsRes;
    }
    set listServerLogs(value: ClusterCtlProtoListServerLogsRes) {
        pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0], value);
    }
    get hasListServerLogs() {
        return pb_1.Message.getField(this, 8) != null;
    }
    get res() {
        const cases: {
            [index: number]: "none" | "getAccessLevels" | "getLatestTypedb" | "getClusterOptions" | "getClusterPrice" | "getClusterPresetPrice" | "getCluster" | "listServers" | "listServerLogs";
        } = {
            0: "none",
            1: "getAccessLevels",
            2: "getLatestTypedb",
            3: "getClusterOptions",
            4: "getClusterPrice",
            5: "getClusterPresetPrice",
            6: "getCluster",
            7: "listServers",
            8: "listServerLogs"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 4, 5, 6, 7, 8])];
    }
    static fromObject(data: {
        getAccessLevels?: ReturnType<typeof ClusterCtlProtoGetAccessLevelsRes.prototype.toObject>;
        getLatestTypedb?: ReturnType<typeof ClusterCtlProtoGetLatestTypeDBRes.prototype.toObject>;
        getClusterOptions?: ReturnType<typeof ClusterCtlProtoGetClusterOptionsRes.prototype.toObject>;
        getClusterPrice?: ReturnType<typeof ClusterCtlProtoGetClusterPriceRes.prototype.toObject>;
        getClusterPresetPrice?: ReturnType<typeof ClusterCtlProtoGetClusterPresetPriceRes.prototype.toObject>;
        getCluster?: ReturnType<typeof ClusterCtlProtoGetClusterRes.prototype.toObject>;
        listServers?: ReturnType<typeof ClusterCtlProtoListServersRes.prototype.toObject>;
        listServerLogs?: ReturnType<typeof ClusterCtlProtoListServerLogsRes.prototype.toObject>;
    }): ClusterCtlProtoRes {
        const message = new ClusterCtlProtoRes({});
        if (data.getAccessLevels != null) {
            message.getAccessLevels = ClusterCtlProtoGetAccessLevelsRes.fromObject(data.getAccessLevels);
        }
        if (data.getLatestTypedb != null) {
            message.getLatestTypedb = ClusterCtlProtoGetLatestTypeDBRes.fromObject(data.getLatestTypedb);
        }
        if (data.getClusterOptions != null) {
            message.getClusterOptions = ClusterCtlProtoGetClusterOptionsRes.fromObject(data.getClusterOptions);
        }
        if (data.getClusterPrice != null) {
            message.getClusterPrice = ClusterCtlProtoGetClusterPriceRes.fromObject(data.getClusterPrice);
        }
        if (data.getClusterPresetPrice != null) {
            message.getClusterPresetPrice = ClusterCtlProtoGetClusterPresetPriceRes.fromObject(data.getClusterPresetPrice);
        }
        if (data.getCluster != null) {
            message.getCluster = ClusterCtlProtoGetClusterRes.fromObject(data.getCluster);
        }
        if (data.listServers != null) {
            message.listServers = ClusterCtlProtoListServersRes.fromObject(data.listServers);
        }
        if (data.listServerLogs != null) {
            message.listServerLogs = ClusterCtlProtoListServerLogsRes.fromObject(data.listServerLogs);
        }
        return message;
    }
    toObject() {
        const data: {
            getAccessLevels?: ReturnType<typeof ClusterCtlProtoGetAccessLevelsRes.prototype.toObject>;
            getLatestTypedb?: ReturnType<typeof ClusterCtlProtoGetLatestTypeDBRes.prototype.toObject>;
            getClusterOptions?: ReturnType<typeof ClusterCtlProtoGetClusterOptionsRes.prototype.toObject>;
            getClusterPrice?: ReturnType<typeof ClusterCtlProtoGetClusterPriceRes.prototype.toObject>;
            getClusterPresetPrice?: ReturnType<typeof ClusterCtlProtoGetClusterPresetPriceRes.prototype.toObject>;
            getCluster?: ReturnType<typeof ClusterCtlProtoGetClusterRes.prototype.toObject>;
            listServers?: ReturnType<typeof ClusterCtlProtoListServersRes.prototype.toObject>;
            listServerLogs?: ReturnType<typeof ClusterCtlProtoListServerLogsRes.prototype.toObject>;
        } = {};
        if (this.getAccessLevels != null) {
            data.getAccessLevels = this.getAccessLevels.toObject();
        }
        if (this.getLatestTypedb != null) {
            data.getLatestTypedb = this.getLatestTypedb.toObject();
        }
        if (this.getClusterOptions != null) {
            data.getClusterOptions = this.getClusterOptions.toObject();
        }
        if (this.getClusterPrice != null) {
            data.getClusterPrice = this.getClusterPrice.toObject();
        }
        if (this.getClusterPresetPrice != null) {
            data.getClusterPresetPrice = this.getClusterPresetPrice.toObject();
        }
        if (this.getCluster != null) {
            data.getCluster = this.getCluster.toObject();
        }
        if (this.listServers != null) {
            data.listServers = this.listServers.toObject();
        }
        if (this.listServerLogs != null) {
            data.listServerLogs = this.listServerLogs.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasGetAccessLevels)
            writer.writeMessage(1, this.getAccessLevels, () => this.getAccessLevels.serialize(writer));
        if (this.hasGetLatestTypedb)
            writer.writeMessage(2, this.getLatestTypedb, () => this.getLatestTypedb.serialize(writer));
        if (this.hasGetClusterOptions)
            writer.writeMessage(3, this.getClusterOptions, () => this.getClusterOptions.serialize(writer));
        if (this.hasGetClusterPrice)
            writer.writeMessage(4, this.getClusterPrice, () => this.getClusterPrice.serialize(writer));
        if (this.hasGetClusterPresetPrice)
            writer.writeMessage(5, this.getClusterPresetPrice, () => this.getClusterPresetPrice.serialize(writer));
        if (this.hasGetCluster)
            writer.writeMessage(6, this.getCluster, () => this.getCluster.serialize(writer));
        if (this.hasListServers)
            writer.writeMessage(7, this.listServers, () => this.listServers.serialize(writer));
        if (this.hasListServerLogs)
            writer.writeMessage(8, this.listServerLogs, () => this.listServerLogs.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.getAccessLevels, () => message.getAccessLevels = ClusterCtlProtoGetAccessLevelsRes.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.getLatestTypedb, () => message.getLatestTypedb = ClusterCtlProtoGetLatestTypeDBRes.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.getClusterOptions, () => message.getClusterOptions = ClusterCtlProtoGetClusterOptionsRes.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.getClusterPrice, () => message.getClusterPrice = ClusterCtlProtoGetClusterPriceRes.deserialize(reader));
                    break;
                case 5:
                    reader.readMessage(message.getClusterPresetPrice, () => message.getClusterPresetPrice = ClusterCtlProtoGetClusterPresetPriceRes.deserialize(reader));
                    break;
                case 6:
                    reader.readMessage(message.getCluster, () => message.getCluster = ClusterCtlProtoGetClusterRes.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.listServers, () => message.listServers = ClusterCtlProtoListServersRes.deserialize(reader));
                    break;
                case 8:
                    reader.readMessage(message.listServerLogs, () => message.listServerLogs = ClusterCtlProtoListServerLogsRes.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoRes {
        return ClusterCtlProtoRes.deserialize(bytes);
    }
}
export class ClusterCtlProtoGetAccessLevels extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ClusterCtlProtoGetAccessLevels {
        const message = new ClusterCtlProtoGetAccessLevels({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoGetAccessLevels {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoGetAccessLevels();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoGetAccessLevels {
        return ClusterCtlProtoGetAccessLevels.deserialize(bytes);
    }
}
export class ClusterCtlProtoGetAccessLevelsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        clusterUuids?: Uint8Array[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("clusterUuids" in data && data.clusterUuids != undefined) {
                this.clusterUuids = data.clusterUuids;
            }
        }
    }
    get clusterUuids() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as Uint8Array[];
    }
    set clusterUuids(value: Uint8Array[]) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        clusterUuids?: Uint8Array[];
    }): ClusterCtlProtoGetAccessLevelsReq {
        const message = new ClusterCtlProtoGetAccessLevelsReq({});
        if (data.clusterUuids != null) {
            message.clusterUuids = data.clusterUuids;
        }
        return message;
    }
    toObject() {
        const data: {
            clusterUuids?: Uint8Array[];
        } = {};
        if (this.clusterUuids != null) {
            data.clusterUuids = this.clusterUuids;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.clusterUuids.length)
            writer.writeRepeatedBytes(1, this.clusterUuids);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoGetAccessLevelsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoGetAccessLevelsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    pb_1.Message.addToRepeatedField(message, 1, reader.readBytes());
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoGetAccessLevelsReq {
        return ClusterCtlProtoGetAccessLevelsReq.deserialize(bytes);
    }
}
export class ClusterCtlProtoGetAccessLevelsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        accessLevels?: dependency_1.AccessLevelProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("accessLevels" in data && data.accessLevels != undefined) {
                this.accessLevels = data.accessLevels;
            }
        }
    }
    get accessLevels() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as dependency_1.AccessLevelProto[];
    }
    set accessLevels(value: dependency_1.AccessLevelProto[]) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        accessLevels?: dependency_1.AccessLevelProto[];
    }): ClusterCtlProtoGetAccessLevelsRes {
        const message = new ClusterCtlProtoGetAccessLevelsRes({});
        if (data.accessLevels != null) {
            message.accessLevels = data.accessLevels;
        }
        return message;
    }
    toObject() {
        const data: {
            accessLevels?: dependency_1.AccessLevelProto[];
        } = {};
        if (this.accessLevels != null) {
            data.accessLevels = this.accessLevels;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.accessLevels.length)
            writer.writePackedEnum(1, this.accessLevels);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoGetAccessLevelsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoGetAccessLevelsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.accessLevels = reader.readPackedEnum();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoGetAccessLevelsRes {
        return ClusterCtlProtoGetAccessLevelsRes.deserialize(bytes);
    }
}
export class ClusterCtlProtoGetClusterOptions extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ClusterCtlProtoGetClusterOptions {
        const message = new ClusterCtlProtoGetClusterOptions({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoGetClusterOptions {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoGetClusterOptions();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoGetClusterOptions {
        return ClusterCtlProtoGetClusterOptions.deserialize(bytes);
    }
}
export class ClusterCtlProtoGetClusterOptionsReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ClusterCtlProtoGetClusterOptionsReq {
        const message = new ClusterCtlProtoGetClusterOptionsReq({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoGetClusterOptionsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoGetClusterOptionsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoGetClusterOptionsReq {
        return ClusterCtlProtoGetClusterOptionsReq.deserialize(bytes);
    }
}
export class ClusterCtlProtoGetClusterOptionsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        gcp?: dependency_1.ProviderProto;
        aws?: dependency_1.ProviderProto;
        azure?: dependency_1.ProviderProto;
        typedbVersions?: dependency_1.TypeDBVersionProto[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [4], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("gcp" in data && data.gcp != undefined) {
                this.gcp = data.gcp;
            }
            if ("aws" in data && data.aws != undefined) {
                this.aws = data.aws;
            }
            if ("azure" in data && data.azure != undefined) {
                this.azure = data.azure;
            }
            if ("typedbVersions" in data && data.typedbVersions != undefined) {
                this.typedbVersions = data.typedbVersions;
            }
        }
    }
    get gcp() {
        return pb_1.Message.getWrapperField(this, dependency_1.ProviderProto, 1) as dependency_1.ProviderProto;
    }
    set gcp(value: dependency_1.ProviderProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasGcp() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get aws() {
        return pb_1.Message.getWrapperField(this, dependency_1.ProviderProto, 2) as dependency_1.ProviderProto;
    }
    set aws(value: dependency_1.ProviderProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasAws() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get azure() {
        return pb_1.Message.getWrapperField(this, dependency_1.ProviderProto, 3) as dependency_1.ProviderProto;
    }
    set azure(value: dependency_1.ProviderProto) {
        pb_1.Message.setWrapperField(this, 3, value);
    }
    get hasAzure() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get typedbVersions() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_1.TypeDBVersionProto, 4) as dependency_1.TypeDBVersionProto[];
    }
    set typedbVersions(value: dependency_1.TypeDBVersionProto[]) {
        pb_1.Message.setRepeatedWrapperField(this, 4, value);
    }
    static fromObject(data: {
        gcp?: ReturnType<typeof dependency_1.ProviderProto.prototype.toObject>;
        aws?: ReturnType<typeof dependency_1.ProviderProto.prototype.toObject>;
        azure?: ReturnType<typeof dependency_1.ProviderProto.prototype.toObject>;
        typedbVersions?: ReturnType<typeof dependency_1.TypeDBVersionProto.prototype.toObject>[];
    }): ClusterCtlProtoGetClusterOptionsRes {
        const message = new ClusterCtlProtoGetClusterOptionsRes({});
        if (data.gcp != null) {
            message.gcp = dependency_1.ProviderProto.fromObject(data.gcp);
        }
        if (data.aws != null) {
            message.aws = dependency_1.ProviderProto.fromObject(data.aws);
        }
        if (data.azure != null) {
            message.azure = dependency_1.ProviderProto.fromObject(data.azure);
        }
        if (data.typedbVersions != null) {
            message.typedbVersions = data.typedbVersions.map(item => dependency_1.TypeDBVersionProto.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            gcp?: ReturnType<typeof dependency_1.ProviderProto.prototype.toObject>;
            aws?: ReturnType<typeof dependency_1.ProviderProto.prototype.toObject>;
            azure?: ReturnType<typeof dependency_1.ProviderProto.prototype.toObject>;
            typedbVersions?: ReturnType<typeof dependency_1.TypeDBVersionProto.prototype.toObject>[];
        } = {};
        if (this.gcp != null) {
            data.gcp = this.gcp.toObject();
        }
        if (this.aws != null) {
            data.aws = this.aws.toObject();
        }
        if (this.azure != null) {
            data.azure = this.azure.toObject();
        }
        if (this.typedbVersions != null) {
            data.typedbVersions = this.typedbVersions.map((item: dependency_1.TypeDBVersionProto) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasGcp)
            writer.writeMessage(1, this.gcp, () => this.gcp.serialize(writer));
        if (this.hasAws)
            writer.writeMessage(2, this.aws, () => this.aws.serialize(writer));
        if (this.hasAzure)
            writer.writeMessage(3, this.azure, () => this.azure.serialize(writer));
        if (this.typedbVersions.length)
            writer.writeRepeatedMessage(4, this.typedbVersions, (item: dependency_1.TypeDBVersionProto) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoGetClusterOptionsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoGetClusterOptionsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.gcp, () => message.gcp = dependency_1.ProviderProto.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.aws, () => message.aws = dependency_1.ProviderProto.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.azure, () => message.azure = dependency_1.ProviderProto.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.typedbVersions, () => pb_1.Message.addToRepeatedWrapperField(message, 4, dependency_1.TypeDBVersionProto.deserialize(reader), dependency_1.TypeDBVersionProto));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoGetClusterOptionsRes {
        return ClusterCtlProtoGetClusterOptionsRes.deserialize(bytes);
    }
}
export class ClusterCtlProtoGetClusterPrice extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ClusterCtlProtoGetClusterPrice {
        const message = new ClusterCtlProtoGetClusterPrice({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoGetClusterPrice {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoGetClusterPrice();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoGetClusterPrice {
        return ClusterCtlProtoGetClusterPrice.deserialize(bytes);
    }
}
export class ClusterCtlProtoGetClusterPriceReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
        cluster?: dependency_1.ClusterProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
            if ("cluster" in data && data.cluster != undefined) {
                this.cluster = data.cluster;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get cluster() {
        return pb_1.Message.getWrapperField(this, dependency_1.ClusterProto, 2) as dependency_1.ClusterProto;
    }
    set cluster(value: dependency_1.ClusterProto) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasCluster() {
        return pb_1.Message.getField(this, 2) != null;
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
        cluster?: ReturnType<typeof dependency_1.ClusterProto.prototype.toObject>;
    }): ClusterCtlProtoGetClusterPriceReq {
        const message = new ClusterCtlProtoGetClusterPriceReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        if (data.cluster != null) {
            message.cluster = dependency_1.ClusterProto.fromObject(data.cluster);
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
            cluster?: ReturnType<typeof dependency_1.ClusterProto.prototype.toObject>;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        if (this.cluster != null) {
            data.cluster = this.cluster.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (this.hasCluster)
            writer.writeMessage(2, this.cluster, () => this.cluster.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoGetClusterPriceReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoGetClusterPriceReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                case 2:
                    reader.readMessage(message.cluster, () => message.cluster = dependency_1.ClusterProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoGetClusterPriceReq {
        return ClusterCtlProtoGetClusterPriceReq.deserialize(bytes);
    }
}
export class ClusterCtlProtoGetClusterPriceRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        price?: dependency_1.ClusterPriceProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("price" in data && data.price != undefined) {
                this.price = data.price;
            }
        }
    }
    get price() {
        return pb_1.Message.getWrapperField(this, dependency_1.ClusterPriceProto, 1) as dependency_1.ClusterPriceProto;
    }
    set price(value: dependency_1.ClusterPriceProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasPrice() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        price?: ReturnType<typeof dependency_1.ClusterPriceProto.prototype.toObject>;
    }): ClusterCtlProtoGetClusterPriceRes {
        const message = new ClusterCtlProtoGetClusterPriceRes({});
        if (data.price != null) {
            message.price = dependency_1.ClusterPriceProto.fromObject(data.price);
        }
        return message;
    }
    toObject() {
        const data: {
            price?: ReturnType<typeof dependency_1.ClusterPriceProto.prototype.toObject>;
        } = {};
        if (this.price != null) {
            data.price = this.price.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasPrice)
            writer.writeMessage(1, this.price, () => this.price.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoGetClusterPriceRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoGetClusterPriceRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.price, () => message.price = dependency_1.ClusterPriceProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoGetClusterPriceRes {
        return ClusterCtlProtoGetClusterPriceRes.deserialize(bytes);
    }
}
export class ClusterCtlProtoGetClusterPresetPrice extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ClusterCtlProtoGetClusterPresetPrice {
        const message = new ClusterCtlProtoGetClusterPresetPrice({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoGetClusterPresetPrice {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoGetClusterPresetPrice();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoGetClusterPresetPrice {
        return ClusterCtlProtoGetClusterPresetPrice.deserialize(bytes);
    }
}
export class ClusterCtlProtoGetClusterPresetPriceReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        orgUuid?: Uint8Array;
        regionId?: string;
        presetId?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("orgUuid" in data && data.orgUuid != undefined) {
                this.orgUuid = data.orgUuid;
            }
            if ("regionId" in data && data.regionId != undefined) {
                this.regionId = data.regionId;
            }
            if ("presetId" in data && data.presetId != undefined) {
                this.presetId = data.presetId;
            }
        }
    }
    get orgUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set orgUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get regionId() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set regionId(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get presetId() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set presetId(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data: {
        orgUuid?: Uint8Array;
        regionId?: string;
        presetId?: string;
    }): ClusterCtlProtoGetClusterPresetPriceReq {
        const message = new ClusterCtlProtoGetClusterPresetPriceReq({});
        if (data.orgUuid != null) {
            message.orgUuid = data.orgUuid;
        }
        if (data.regionId != null) {
            message.regionId = data.regionId;
        }
        if (data.presetId != null) {
            message.presetId = data.presetId;
        }
        return message;
    }
    toObject() {
        const data: {
            orgUuid?: Uint8Array;
            regionId?: string;
            presetId?: string;
        } = {};
        if (this.orgUuid != null) {
            data.orgUuid = this.orgUuid;
        }
        if (this.regionId != null) {
            data.regionId = this.regionId;
        }
        if (this.presetId != null) {
            data.presetId = this.presetId;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.orgUuid.length)
            writer.writeBytes(1, this.orgUuid);
        if (this.regionId.length)
            writer.writeString(2, this.regionId);
        if (this.presetId.length)
            writer.writeString(3, this.presetId);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoGetClusterPresetPriceReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoGetClusterPresetPriceReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.orgUuid = reader.readBytes();
                    break;
                case 2:
                    message.regionId = reader.readString();
                    break;
                case 3:
                    message.presetId = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoGetClusterPresetPriceReq {
        return ClusterCtlProtoGetClusterPresetPriceReq.deserialize(bytes);
    }
}
export class ClusterCtlProtoGetClusterPresetPriceRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        price?: dependency_1.ClusterPriceProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("price" in data && data.price != undefined) {
                this.price = data.price;
            }
        }
    }
    get price() {
        return pb_1.Message.getWrapperField(this, dependency_1.ClusterPriceProto, 1) as dependency_1.ClusterPriceProto;
    }
    set price(value: dependency_1.ClusterPriceProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasPrice() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        price?: ReturnType<typeof dependency_1.ClusterPriceProto.prototype.toObject>;
    }): ClusterCtlProtoGetClusterPresetPriceRes {
        const message = new ClusterCtlProtoGetClusterPresetPriceRes({});
        if (data.price != null) {
            message.price = dependency_1.ClusterPriceProto.fromObject(data.price);
        }
        return message;
    }
    toObject() {
        const data: {
            price?: ReturnType<typeof dependency_1.ClusterPriceProto.prototype.toObject>;
        } = {};
        if (this.price != null) {
            data.price = this.price.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasPrice)
            writer.writeMessage(1, this.price, () => this.price.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoGetClusterPresetPriceRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoGetClusterPresetPriceRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.price, () => message.price = dependency_1.ClusterPriceProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoGetClusterPresetPriceRes {
        return ClusterCtlProtoGetClusterPresetPriceRes.deserialize(bytes);
    }
}
export class ClusterCtlProtoDeployCluster extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ClusterCtlProtoDeployCluster {
        const message = new ClusterCtlProtoDeployCluster({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoDeployCluster {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoDeployCluster();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoDeployCluster {
        return ClusterCtlProtoDeployCluster.deserialize(bytes);
    }
}
export class ClusterCtlProtoDeployClusterReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        cluster?: dependency_1.ClusterProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("cluster" in data && data.cluster != undefined) {
                this.cluster = data.cluster;
            }
        }
    }
    get cluster() {
        return pb_1.Message.getWrapperField(this, dependency_1.ClusterProto, 1) as dependency_1.ClusterProto;
    }
    set cluster(value: dependency_1.ClusterProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasCluster() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        cluster?: ReturnType<typeof dependency_1.ClusterProto.prototype.toObject>;
    }): ClusterCtlProtoDeployClusterReq {
        const message = new ClusterCtlProtoDeployClusterReq({});
        if (data.cluster != null) {
            message.cluster = dependency_1.ClusterProto.fromObject(data.cluster);
        }
        return message;
    }
    toObject() {
        const data: {
            cluster?: ReturnType<typeof dependency_1.ClusterProto.prototype.toObject>;
        } = {};
        if (this.cluster != null) {
            data.cluster = this.cluster.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasCluster)
            writer.writeMessage(1, this.cluster, () => this.cluster.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoDeployClusterReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoDeployClusterReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.cluster, () => message.cluster = dependency_1.ClusterProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoDeployClusterReq {
        return ClusterCtlProtoDeployClusterReq.deserialize(bytes);
    }
}
export class ClusterCtlProtoGetCluster extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ClusterCtlProtoGetCluster {
        const message = new ClusterCtlProtoGetCluster({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoGetCluster {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoGetCluster();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoGetCluster {
        return ClusterCtlProtoGetCluster.deserialize(bytes);
    }
}
export class ClusterCtlProtoGetClusterReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        projectUuid?: Uint8Array;
        clusterId?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("projectUuid" in data && data.projectUuid != undefined) {
                this.projectUuid = data.projectUuid;
            }
            if ("clusterId" in data && data.clusterId != undefined) {
                this.clusterId = data.clusterId;
            }
        }
    }
    get projectUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set projectUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get clusterId() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set clusterId(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        projectUuid?: Uint8Array;
        clusterId?: string;
    }): ClusterCtlProtoGetClusterReq {
        const message = new ClusterCtlProtoGetClusterReq({});
        if (data.projectUuid != null) {
            message.projectUuid = data.projectUuid;
        }
        if (data.clusterId != null) {
            message.clusterId = data.clusterId;
        }
        return message;
    }
    toObject() {
        const data: {
            projectUuid?: Uint8Array;
            clusterId?: string;
        } = {};
        if (this.projectUuid != null) {
            data.projectUuid = this.projectUuid;
        }
        if (this.clusterId != null) {
            data.clusterId = this.clusterId;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.projectUuid.length)
            writer.writeBytes(1, this.projectUuid);
        if (this.clusterId.length)
            writer.writeString(2, this.clusterId);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoGetClusterReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoGetClusterReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.projectUuid = reader.readBytes();
                    break;
                case 2:
                    message.clusterId = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoGetClusterReq {
        return ClusterCtlProtoGetClusterReq.deserialize(bytes);
    }
}
export class ClusterCtlProtoGetClusterRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        cluster?: dependency_1.ClusterProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("cluster" in data && data.cluster != undefined) {
                this.cluster = data.cluster;
            }
        }
    }
    get cluster() {
        return pb_1.Message.getWrapperField(this, dependency_1.ClusterProto, 1) as dependency_1.ClusterProto;
    }
    set cluster(value: dependency_1.ClusterProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasCluster() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        cluster?: ReturnType<typeof dependency_1.ClusterProto.prototype.toObject>;
    }): ClusterCtlProtoGetClusterRes {
        const message = new ClusterCtlProtoGetClusterRes({});
        if (data.cluster != null) {
            message.cluster = dependency_1.ClusterProto.fromObject(data.cluster);
        }
        return message;
    }
    toObject() {
        const data: {
            cluster?: ReturnType<typeof dependency_1.ClusterProto.prototype.toObject>;
        } = {};
        if (this.cluster != null) {
            data.cluster = this.cluster.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasCluster)
            writer.writeMessage(1, this.cluster, () => this.cluster.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoGetClusterRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoGetClusterRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.cluster, () => message.cluster = dependency_1.ClusterProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoGetClusterRes {
        return ClusterCtlProtoGetClusterRes.deserialize(bytes);
    }
}
export class ClusterCtlProtoUpdateCluster extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ClusterCtlProtoUpdateCluster {
        const message = new ClusterCtlProtoUpdateCluster({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoUpdateCluster {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoUpdateCluster();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoUpdateCluster {
        return ClusterCtlProtoUpdateCluster.deserialize(bytes);
    }
}
export class ClusterCtlProtoUpdateClusterReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        cluster?: dependency_1.ClusterProto;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("cluster" in data && data.cluster != undefined) {
                this.cluster = data.cluster;
            }
        }
    }
    get cluster() {
        return pb_1.Message.getWrapperField(this, dependency_1.ClusterProto, 1) as dependency_1.ClusterProto;
    }
    set cluster(value: dependency_1.ClusterProto) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasCluster() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        cluster?: ReturnType<typeof dependency_1.ClusterProto.prototype.toObject>;
    }): ClusterCtlProtoUpdateClusterReq {
        const message = new ClusterCtlProtoUpdateClusterReq({});
        if (data.cluster != null) {
            message.cluster = dependency_1.ClusterProto.fromObject(data.cluster);
        }
        return message;
    }
    toObject() {
        const data: {
            cluster?: ReturnType<typeof dependency_1.ClusterProto.prototype.toObject>;
        } = {};
        if (this.cluster != null) {
            data.cluster = this.cluster.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasCluster)
            writer.writeMessage(1, this.cluster, () => this.cluster.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoUpdateClusterReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoUpdateClusterReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.cluster, () => message.cluster = dependency_1.ClusterProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoUpdateClusterReq {
        return ClusterCtlProtoUpdateClusterReq.deserialize(bytes);
    }
}
export class ClusterCtlProtoUpdateToLatestTypeDB extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ClusterCtlProtoUpdateToLatestTypeDB {
        const message = new ClusterCtlProtoUpdateToLatestTypeDB({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoUpdateToLatestTypeDB {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoUpdateToLatestTypeDB();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoUpdateToLatestTypeDB {
        return ClusterCtlProtoUpdateToLatestTypeDB.deserialize(bytes);
    }
}
export class ClusterCtlProtoUpdateToLatestTypeDBReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        clusterUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("clusterUuid" in data && data.clusterUuid != undefined) {
                this.clusterUuid = data.clusterUuid;
            }
        }
    }
    get clusterUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set clusterUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        clusterUuid?: Uint8Array;
    }): ClusterCtlProtoUpdateToLatestTypeDBReq {
        const message = new ClusterCtlProtoUpdateToLatestTypeDBReq({});
        if (data.clusterUuid != null) {
            message.clusterUuid = data.clusterUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            clusterUuid?: Uint8Array;
        } = {};
        if (this.clusterUuid != null) {
            data.clusterUuid = this.clusterUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.clusterUuid.length)
            writer.writeBytes(1, this.clusterUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoUpdateToLatestTypeDBReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoUpdateToLatestTypeDBReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.clusterUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoUpdateToLatestTypeDBReq {
        return ClusterCtlProtoUpdateToLatestTypeDBReq.deserialize(bytes);
    }
}
export class ClusterCtlProtoSuspendCluster extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ClusterCtlProtoSuspendCluster {
        const message = new ClusterCtlProtoSuspendCluster({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoSuspendCluster {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoSuspendCluster();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoSuspendCluster {
        return ClusterCtlProtoSuspendCluster.deserialize(bytes);
    }
}
export class ClusterCtlProtoSuspendClusterReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        clusterUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("clusterUuid" in data && data.clusterUuid != undefined) {
                this.clusterUuid = data.clusterUuid;
            }
        }
    }
    get clusterUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set clusterUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        clusterUuid?: Uint8Array;
    }): ClusterCtlProtoSuspendClusterReq {
        const message = new ClusterCtlProtoSuspendClusterReq({});
        if (data.clusterUuid != null) {
            message.clusterUuid = data.clusterUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            clusterUuid?: Uint8Array;
        } = {};
        if (this.clusterUuid != null) {
            data.clusterUuid = this.clusterUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.clusterUuid.length)
            writer.writeBytes(1, this.clusterUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoSuspendClusterReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoSuspendClusterReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.clusterUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoSuspendClusterReq {
        return ClusterCtlProtoSuspendClusterReq.deserialize(bytes);
    }
}
export class ClusterCtlProtoResumeCluster extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ClusterCtlProtoResumeCluster {
        const message = new ClusterCtlProtoResumeCluster({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoResumeCluster {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoResumeCluster();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoResumeCluster {
        return ClusterCtlProtoResumeCluster.deserialize(bytes);
    }
}
export class ClusterCtlProtoResumeClusterReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        clusterUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("clusterUuid" in data && data.clusterUuid != undefined) {
                this.clusterUuid = data.clusterUuid;
            }
        }
    }
    get clusterUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set clusterUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        clusterUuid?: Uint8Array;
    }): ClusterCtlProtoResumeClusterReq {
        const message = new ClusterCtlProtoResumeClusterReq({});
        if (data.clusterUuid != null) {
            message.clusterUuid = data.clusterUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            clusterUuid?: Uint8Array;
        } = {};
        if (this.clusterUuid != null) {
            data.clusterUuid = this.clusterUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.clusterUuid.length)
            writer.writeBytes(1, this.clusterUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoResumeClusterReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoResumeClusterReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.clusterUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoResumeClusterReq {
        return ClusterCtlProtoResumeClusterReq.deserialize(bytes);
    }
}
export class ClusterCtlProtoDestroyCluster extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ClusterCtlProtoDestroyCluster {
        const message = new ClusterCtlProtoDestroyCluster({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoDestroyCluster {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoDestroyCluster();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoDestroyCluster {
        return ClusterCtlProtoDestroyCluster.deserialize(bytes);
    }
}
export class ClusterCtlProtoDestroyClusterReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        clusterUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("clusterUuid" in data && data.clusterUuid != undefined) {
                this.clusterUuid = data.clusterUuid;
            }
        }
    }
    get clusterUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set clusterUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        clusterUuid?: Uint8Array;
    }): ClusterCtlProtoDestroyClusterReq {
        const message = new ClusterCtlProtoDestroyClusterReq({});
        if (data.clusterUuid != null) {
            message.clusterUuid = data.clusterUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            clusterUuid?: Uint8Array;
        } = {};
        if (this.clusterUuid != null) {
            data.clusterUuid = this.clusterUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.clusterUuid.length)
            writer.writeBytes(1, this.clusterUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoDestroyClusterReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoDestroyClusterReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.clusterUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoDestroyClusterReq {
        return ClusterCtlProtoDestroyClusterReq.deserialize(bytes);
    }
}
export class ClusterCtlProtoListServers extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ClusterCtlProtoListServers {
        const message = new ClusterCtlProtoListServers({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoListServers {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoListServers();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoListServers {
        return ClusterCtlProtoListServers.deserialize(bytes);
    }
}
export class ClusterCtlProtoListServersReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        clusterUuid?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("clusterUuid" in data && data.clusterUuid != undefined) {
                this.clusterUuid = data.clusterUuid;
            }
        }
    }
    get clusterUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set clusterUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        clusterUuid?: Uint8Array;
    }): ClusterCtlProtoListServersReq {
        const message = new ClusterCtlProtoListServersReq({});
        if (data.clusterUuid != null) {
            message.clusterUuid = data.clusterUuid;
        }
        return message;
    }
    toObject() {
        const data: {
            clusterUuid?: Uint8Array;
        } = {};
        if (this.clusterUuid != null) {
            data.clusterUuid = this.clusterUuid;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.clusterUuid.length)
            writer.writeBytes(1, this.clusterUuid);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoListServersReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoListServersReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.clusterUuid = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoListServersReq {
        return ClusterCtlProtoListServersReq.deserialize(bytes);
    }
}
export class ClusterCtlProtoListServersRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        servers?: dependency_1.ClusterProtoServer[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("servers" in data && data.servers != undefined) {
                this.servers = data.servers;
            }
        }
    }
    get servers() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_1.ClusterProtoServer, 1) as dependency_1.ClusterProtoServer[];
    }
    set servers(value: dependency_1.ClusterProtoServer[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data: {
        servers?: ReturnType<typeof dependency_1.ClusterProtoServer.prototype.toObject>[];
    }): ClusterCtlProtoListServersRes {
        const message = new ClusterCtlProtoListServersRes({});
        if (data.servers != null) {
            message.servers = data.servers.map(item => dependency_1.ClusterProtoServer.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: {
            servers?: ReturnType<typeof dependency_1.ClusterProtoServer.prototype.toObject>[];
        } = {};
        if (this.servers != null) {
            data.servers = this.servers.map((item: dependency_1.ClusterProtoServer) => item.toObject());
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.servers.length)
            writer.writeRepeatedMessage(1, this.servers, (item: dependency_1.ClusterProtoServer) => item.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoListServersRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoListServersRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.servers, () => pb_1.Message.addToRepeatedWrapperField(message, 1, dependency_1.ClusterProtoServer.deserialize(reader), dependency_1.ClusterProtoServer));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoListServersRes {
        return ClusterCtlProtoListServersRes.deserialize(bytes);
    }
}
export class ClusterCtlProtoListServerLogs extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ClusterCtlProtoListServerLogs {
        const message = new ClusterCtlProtoListServerLogs({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoListServerLogs {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoListServerLogs();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoListServerLogs {
        return ClusterCtlProtoListServerLogs.deserialize(bytes);
    }
}
export class ClusterCtlProtoListServerLogsReq extends pb_1.Message {
    #one_of_decls: number[][] = [[4]];
    constructor(data?: any[] | ({
        clusterUuid?: Uint8Array;
        serverUuid?: Uint8Array;
        serverIndex?: number;
        snapshot?: boolean;
    } & (({
        initialLineCount?: number;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("clusterUuid" in data && data.clusterUuid != undefined) {
                this.clusterUuid = data.clusterUuid;
            }
            if ("serverUuid" in data && data.serverUuid != undefined) {
                this.serverUuid = data.serverUuid;
            }
            if ("serverIndex" in data && data.serverIndex != undefined) {
                this.serverIndex = data.serverIndex;
            }
            if ("initialLineCount" in data && data.initialLineCount != undefined) {
                this.initialLineCount = data.initialLineCount;
            }
            if ("snapshot" in data && data.snapshot != undefined) {
                this.snapshot = data.snapshot;
            }
        }
    }
    get clusterUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set clusterUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get serverUuid() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set serverUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    get serverIndex() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set serverIndex(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get initialLineCount() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set initialLineCount(value: number) {
        pb_1.Message.setOneofField(this, 4, this.#one_of_decls[0], value);
    }
    get hasInitialLineCount() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get snapshot() {
        return pb_1.Message.getFieldWithDefault(this, 5, false) as boolean;
    }
    set snapshot(value: boolean) {
        pb_1.Message.setField(this, 5, value);
    }
    get _initial_line_count() {
        const cases: {
            [index: number]: "none" | "initialLineCount";
        } = {
            0: "none",
            4: "initialLineCount"
        };
        return cases[pb_1.Message.computeOneofCase(this, [4])];
    }
    static fromObject(data: {
        clusterUuid?: Uint8Array;
        serverUuid?: Uint8Array;
        serverIndex?: number;
        initialLineCount?: number;
        snapshot?: boolean;
    }): ClusterCtlProtoListServerLogsReq {
        const message = new ClusterCtlProtoListServerLogsReq({});
        if (data.clusterUuid != null) {
            message.clusterUuid = data.clusterUuid;
        }
        if (data.serverUuid != null) {
            message.serverUuid = data.serverUuid;
        }
        if (data.serverIndex != null) {
            message.serverIndex = data.serverIndex;
        }
        if (data.initialLineCount != null) {
            message.initialLineCount = data.initialLineCount;
        }
        if (data.snapshot != null) {
            message.snapshot = data.snapshot;
        }
        return message;
    }
    toObject() {
        const data: {
            clusterUuid?: Uint8Array;
            serverUuid?: Uint8Array;
            serverIndex?: number;
            initialLineCount?: number;
            snapshot?: boolean;
        } = {};
        if (this.clusterUuid != null) {
            data.clusterUuid = this.clusterUuid;
        }
        if (this.serverUuid != null) {
            data.serverUuid = this.serverUuid;
        }
        if (this.serverIndex != null) {
            data.serverIndex = this.serverIndex;
        }
        if (this.initialLineCount != null) {
            data.initialLineCount = this.initialLineCount;
        }
        if (this.snapshot != null) {
            data.snapshot = this.snapshot;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.clusterUuid.length)
            writer.writeBytes(1, this.clusterUuid);
        if (this.serverUuid.length)
            writer.writeBytes(2, this.serverUuid);
        if (this.serverIndex != 0)
            writer.writeInt32(3, this.serverIndex);
        if (this.hasInitialLineCount)
            writer.writeInt32(4, this.initialLineCount);
        if (this.snapshot != false)
            writer.writeBool(5, this.snapshot);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoListServerLogsReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoListServerLogsReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.clusterUuid = reader.readBytes();
                    break;
                case 2:
                    message.serverUuid = reader.readBytes();
                    break;
                case 3:
                    message.serverIndex = reader.readInt32();
                    break;
                case 4:
                    message.initialLineCount = reader.readInt32();
                    break;
                case 5:
                    message.snapshot = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoListServerLogsReq {
        return ClusterCtlProtoListServerLogsReq.deserialize(bytes);
    }
}
export class ClusterCtlProtoListServerLogsRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        logBatch?: dependency_1.ClusterProtoServerLogBatch;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("logBatch" in data && data.logBatch != undefined) {
                this.logBatch = data.logBatch;
            }
        }
    }
    get logBatch() {
        return pb_1.Message.getWrapperField(this, dependency_1.ClusterProtoServerLogBatch, 1) as dependency_1.ClusterProtoServerLogBatch;
    }
    set logBatch(value: dependency_1.ClusterProtoServerLogBatch) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasLogBatch() {
        return pb_1.Message.getField(this, 1) != null;
    }
    static fromObject(data: {
        logBatch?: ReturnType<typeof dependency_1.ClusterProtoServerLogBatch.prototype.toObject>;
    }): ClusterCtlProtoListServerLogsRes {
        const message = new ClusterCtlProtoListServerLogsRes({});
        if (data.logBatch != null) {
            message.logBatch = dependency_1.ClusterProtoServerLogBatch.fromObject(data.logBatch);
        }
        return message;
    }
    toObject() {
        const data: {
            logBatch?: ReturnType<typeof dependency_1.ClusterProtoServerLogBatch.prototype.toObject>;
        } = {};
        if (this.logBatch != null) {
            data.logBatch = this.logBatch.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasLogBatch)
            writer.writeMessage(1, this.logBatch, () => this.logBatch.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoListServerLogsRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoListServerLogsRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.logBatch, () => message.logBatch = dependency_1.ClusterProtoServerLogBatch.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoListServerLogsRes {
        return ClusterCtlProtoListServerLogsRes.deserialize(bytes);
    }
}
export class ClusterCtlProtoGetLatestTypeDB extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ClusterCtlProtoGetLatestTypeDB {
        const message = new ClusterCtlProtoGetLatestTypeDB({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoGetLatestTypeDB {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoGetLatestTypeDB();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoGetLatestTypeDB {
        return ClusterCtlProtoGetLatestTypeDB.deserialize(bytes);
    }
}
export class ClusterCtlProtoGetLatestTypeDBReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ClusterCtlProtoGetLatestTypeDBReq {
        const message = new ClusterCtlProtoGetLatestTypeDBReq({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoGetLatestTypeDBReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoGetLatestTypeDBReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoGetLatestTypeDBReq {
        return ClusterCtlProtoGetLatestTypeDBReq.deserialize(bytes);
    }
}
export class ClusterCtlProtoGetLatestTypeDBRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        typedbVersion?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("typedbVersion" in data && data.typedbVersion != undefined) {
                this.typedbVersion = data.typedbVersion;
            }
        }
    }
    get typedbVersion() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set typedbVersion(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        typedbVersion?: string;
    }): ClusterCtlProtoGetLatestTypeDBRes {
        const message = new ClusterCtlProtoGetLatestTypeDBRes({});
        if (data.typedbVersion != null) {
            message.typedbVersion = data.typedbVersion;
        }
        return message;
    }
    toObject() {
        const data: {
            typedbVersion?: string;
        } = {};
        if (this.typedbVersion != null) {
            data.typedbVersion = this.typedbVersion;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.typedbVersion.length)
            writer.writeString(1, this.typedbVersion);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoGetLatestTypeDBRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoGetLatestTypeDBRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.typedbVersion = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoGetLatestTypeDBRes {
        return ClusterCtlProtoGetLatestTypeDBRes.deserialize(bytes);
    }
}
export class ClusterCtlProtoSetupCluster extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ClusterCtlProtoSetupCluster {
        const message = new ClusterCtlProtoSetupCluster({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoSetupCluster {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoSetupCluster();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoSetupCluster {
        return ClusterCtlProtoSetupCluster.deserialize(bytes);
    }
}
export class ClusterCtlProtoSetupClusterReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        clusterUuid?: Uint8Array;
        password?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("clusterUuid" in data && data.clusterUuid != undefined) {
                this.clusterUuid = data.clusterUuid;
            }
            if ("password" in data && data.password != undefined) {
                this.password = data.password;
            }
        }
    }
    get clusterUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set clusterUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get password() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set password(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        clusterUuid?: Uint8Array;
        password?: string;
    }): ClusterCtlProtoSetupClusterReq {
        const message = new ClusterCtlProtoSetupClusterReq({});
        if (data.clusterUuid != null) {
            message.clusterUuid = data.clusterUuid;
        }
        if (data.password != null) {
            message.password = data.password;
        }
        return message;
    }
    toObject() {
        const data: {
            clusterUuid?: Uint8Array;
            password?: string;
        } = {};
        if (this.clusterUuid != null) {
            data.clusterUuid = this.clusterUuid;
        }
        if (this.password != null) {
            data.password = this.password;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.clusterUuid.length)
            writer.writeBytes(1, this.clusterUuid);
        if (this.password.length)
            writer.writeString(2, this.password);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoSetupClusterReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoSetupClusterReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.clusterUuid = reader.readBytes();
                    break;
                case 2:
                    message.password = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoSetupClusterReq {
        return ClusterCtlProtoSetupClusterReq.deserialize(bytes);
    }
}
export class ClusterCtlProtoCreateDatabase extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ClusterCtlProtoCreateDatabase {
        const message = new ClusterCtlProtoCreateDatabase({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoCreateDatabase {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoCreateDatabase();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoCreateDatabase {
        return ClusterCtlProtoCreateDatabase.deserialize(bytes);
    }
}
export class ClusterCtlProtoCreateDatabaseReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        clusterUuid?: Uint8Array;
        username?: string;
        password?: string;
        name?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("clusterUuid" in data && data.clusterUuid != undefined) {
                this.clusterUuid = data.clusterUuid;
            }
            if ("username" in data && data.username != undefined) {
                this.username = data.username;
            }
            if ("password" in data && data.password != undefined) {
                this.password = data.password;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
        }
    }
    get clusterUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set clusterUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get username() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set username(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get password() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set password(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    static fromObject(data: {
        clusterUuid?: Uint8Array;
        username?: string;
        password?: string;
        name?: string;
    }): ClusterCtlProtoCreateDatabaseReq {
        const message = new ClusterCtlProtoCreateDatabaseReq({});
        if (data.clusterUuid != null) {
            message.clusterUuid = data.clusterUuid;
        }
        if (data.username != null) {
            message.username = data.username;
        }
        if (data.password != null) {
            message.password = data.password;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        return message;
    }
    toObject() {
        const data: {
            clusterUuid?: Uint8Array;
            username?: string;
            password?: string;
            name?: string;
        } = {};
        if (this.clusterUuid != null) {
            data.clusterUuid = this.clusterUuid;
        }
        if (this.username != null) {
            data.username = this.username;
        }
        if (this.password != null) {
            data.password = this.password;
        }
        if (this.name != null) {
            data.name = this.name;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.clusterUuid.length)
            writer.writeBytes(1, this.clusterUuid);
        if (this.username.length)
            writer.writeString(2, this.username);
        if (this.password.length)
            writer.writeString(3, this.password);
        if (this.name.length)
            writer.writeString(4, this.name);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoCreateDatabaseReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoCreateDatabaseReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.clusterUuid = reader.readBytes();
                    break;
                case 2:
                    message.username = reader.readString();
                    break;
                case 3:
                    message.password = reader.readString();
                    break;
                case 4:
                    message.name = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoCreateDatabaseReq {
        return ClusterCtlProtoCreateDatabaseReq.deserialize(bytes);
    }
}
export class ClusterCtlProtoLoadSampleDataset extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): ClusterCtlProtoLoadSampleDataset {
        const message = new ClusterCtlProtoLoadSampleDataset({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoLoadSampleDataset {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoLoadSampleDataset();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoLoadSampleDataset {
        return ClusterCtlProtoLoadSampleDataset.deserialize(bytes);
    }
}
export class ClusterCtlProtoLoadSampleDatasetReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        clusterUuid?: Uint8Array;
        username?: string;
        password?: string;
        database?: string;
        name?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("clusterUuid" in data && data.clusterUuid != undefined) {
                this.clusterUuid = data.clusterUuid;
            }
            if ("username" in data && data.username != undefined) {
                this.username = data.username;
            }
            if ("password" in data && data.password != undefined) {
                this.password = data.password;
            }
            if ("database" in data && data.database != undefined) {
                this.database = data.database;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
        }
    }
    get clusterUuid() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set clusterUuid(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get username() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set username(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get password() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set password(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get database() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set database(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 5, value);
    }
    static fromObject(data: {
        clusterUuid?: Uint8Array;
        username?: string;
        password?: string;
        database?: string;
        name?: string;
    }): ClusterCtlProtoLoadSampleDatasetReq {
        const message = new ClusterCtlProtoLoadSampleDatasetReq({});
        if (data.clusterUuid != null) {
            message.clusterUuid = data.clusterUuid;
        }
        if (data.username != null) {
            message.username = data.username;
        }
        if (data.password != null) {
            message.password = data.password;
        }
        if (data.database != null) {
            message.database = data.database;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        return message;
    }
    toObject() {
        const data: {
            clusterUuid?: Uint8Array;
            username?: string;
            password?: string;
            database?: string;
            name?: string;
        } = {};
        if (this.clusterUuid != null) {
            data.clusterUuid = this.clusterUuid;
        }
        if (this.username != null) {
            data.username = this.username;
        }
        if (this.password != null) {
            data.password = this.password;
        }
        if (this.database != null) {
            data.database = this.database;
        }
        if (this.name != null) {
            data.name = this.name;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.clusterUuid.length)
            writer.writeBytes(1, this.clusterUuid);
        if (this.username.length)
            writer.writeString(2, this.username);
        if (this.password.length)
            writer.writeString(3, this.password);
        if (this.database.length)
            writer.writeString(4, this.database);
        if (this.name.length)
            writer.writeString(5, this.name);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ClusterCtlProtoLoadSampleDatasetReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ClusterCtlProtoLoadSampleDatasetReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.clusterUuid = reader.readBytes();
                    break;
                case 2:
                    message.username = reader.readString();
                    break;
                case 3:
                    message.password = reader.readString();
                    break;
                case 4:
                    message.database = reader.readString();
                    break;
                case 5:
                    message.name = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): ClusterCtlProtoLoadSampleDatasetReq {
        return ClusterCtlProtoLoadSampleDatasetReq.deserialize(bytes);
    }
}
