/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import {
    AuthenticationCtlProtoAuthTokenReq,
    AuthenticationCtlProtoDeauthUserReq,
    AuthenticationCtlProtoGetProviderForEmailReq,
    AuthenticationCtlProtoPrivateReq,
    AuthenticationCtlProtoPublicReq,
    AuthenticationCtlProtoSendResetPasswordReq,
    AuthenticationCtlProtoStartAuthReq
} from "../../../protocol/authentication-controller";
import { ok, Ok } from "../../concept/base";
import { UserAuth, userAuthOf } from "../../concept/user";
import { map, Observable, tap } from "rxjs";
import { Injectable } from "@angular/core";
import { ApiService } from "../backend/api.service";

@Injectable({
    providedIn: "root",
})
export class PlatformAuthService {
    public constructor(private api: ApiService) {}

    startUserAuth(): Observable<{ tenantId: string, apiKey: string, authDomain: string, posthogApiKey: string, cioTrackApiToken: string }> {
        return this.api.authPublicReq(new AuthenticationCtlProtoPublicReq({ startUserAuth: new AuthenticationCtlProtoStartAuthReq() }))
            .pipe(map((res) => ({
                tenantId: res.startUserAuth.serviceId, apiKey: res.startUserAuth.apiKey,
                authDomain: res.startUserAuth.authDomain, posthogApiKey: res.startUserAuth.posthogApiKey,
                cioTrackApiToken: res.startUserAuth.cioTrackApiToken,
            })));
    }

    authUserToken(token: string): Observable<UserAuth> {
        return this.api.authPublicReq(new AuthenticationCtlProtoPublicReq({ authUserToken: new AuthenticationCtlProtoAuthTokenReq({ token }) })).pipe(
            map((res) => res.authUserToken),
            map((res) => userAuthOf(res.accountStatus, res.hasUser ? res.user : undefined)),
            tap(() => this.api.backend.isAuthenticated$.next(true)),
        );
    }

    deauthUser(): Observable<Ok> {
        return this.api.authPrivateReq(new AuthenticationCtlProtoPrivateReq({ deauthUser: new AuthenticationCtlProtoDeauthUserReq() }))
            .pipe(tap(() => this.api.backend.isAuthenticated$.next(false)));
    }

    sendResetPassword(email: string): Observable<Ok> {
        return this.api.authPublicReq(new AuthenticationCtlProtoPublicReq({
            sendResetPassword: new AuthenticationCtlProtoSendResetPasswordReq({ email })
        })).pipe(map(() => ok));
    }

    getProviderForEmail(email: string): Observable<string> {
        return this.api.authPublicReq(new AuthenticationCtlProtoPublicReq({
            getProviderForEmail: new AuthenticationCtlProtoGetProviderForEmailReq({ email })
        })).pipe(map((res) => res.getProviderForEmail.provider));
    }
}
