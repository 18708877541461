<mat-form-field>
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }
  <input #input matInput [formControl]="formControl" [attr.autocomplete]="autocomplete">
  <div matSuffix>
    <ng-content select="[inputSuffix]"/>
  </div>
  @if (hint) {
    <mat-hint>{{ hint }}</mat-hint>
  }
  @if (formControl.getError("errorText")) {
    <mat-error>{{ formControl.getError("errorText") }}</mat-error>
  }
</mat-form-field>
