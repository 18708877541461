/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

import "typedb-web-common/lib/prism";

import { bootstrapApplication } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { faIconsInitializer } from "./framework/icons-fontawesome";
import { RootComponent } from "./root.component";
import { APP_INITIALIZER, importProvidersFrom, inject } from "@angular/core";
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from "@angular/material/core";
import { FirebaseOptions, initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { getAuth, provideAuth } from "@angular/fire/auth";
import { provideNgxStripe } from "ngx-stripe";
import { provideHttpClient } from "@angular/common/http";
import { routes } from "./routing/routes";
import { TypeDBPlatformTitleStrategy } from "./routing/title-strategy";
import { PlatformAuthService } from "./service/authentication/platform-auth.service";
import { lastValueFrom } from "rxjs";
import { MAT_DIALOG_DEFAULT_OPTIONS, MAT_DIALOG_SCROLL_STRATEGY } from "@angular/material/dialog";
import { provideRouter, TitleStrategy } from "@angular/router";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from "@angular/material/tooltip";
import { FormService } from "./service/form.service";
import { SanityService } from "./service/sanity.service";
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions } from "@angular/material/checkbox";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import "posthog-js/dist/recorder";
import "posthog-js/dist/surveys";
import "posthog-js/dist/exception-autocapture";
import "posthog-js/dist/tracing-headers";
import "posthog-js/dist/web-vitals";
import "posthog-js/dist/dead-clicks-autocapture";
import posthog from "posthog-js/dist/module.no-external";
import Intercom from "@intercom/messenger-js-sdk";
import { Overlay } from "@angular/cdk/overlay";

const rippleGlobalConfig: RippleGlobalOptions = {
    disabled: true,
    animation: {
        enterDuration: 0,
        exitDuration: 0,
    },
};

const tooltipGlobalConfig: MatTooltipDefaultOptions = {
    showDelay: 0,
    hideDelay: 0,
    touchendHideDelay: 0,
    position: "above",
};

const checkboxGlobalConfig: MatCheckboxDefaultOptions = {
    color: "primary"
};

let firebaseOptions: FirebaseOptions & { tenantId: string } = { tenantId: "" };

// TODO: clean up this method
function appInitializer(platformAuth: PlatformAuthService, forms: FormService, library: FaIconLibrary) {
    faIconsInitializer(library);
    return startUserAuthInitializer(platformAuth, forms);
}

function startUserAuthInitializer(service: PlatformAuthService, forms: FormService) {
    return () => lastValueFrom(service.startUserAuth()).then((res) => {
        firebaseOptions = { tenantId: res.tenantId, apiKey: res.apiKey, authDomain: res.authDomain };
        forms.authToken = res.cioTrackApiToken;
        posthog.init(
            res.posthogApiKey,
            {
                api_host: "https://typedb.com/ingest",
                ui_host: "https://us.posthog.com",
                person_profiles: "always",
                capture_pageview: false,
                capture_pageleave: true,
            }
        );
    }).catch((err) => {
        console.error(`Error loading user auth configuration: ${err}`);
    });
}

Intercom({
    app_id: "zof896ic",
    hide_default_launcher: true,
    custom_launcher_selector: "#sidebar_support",
    alignment: "left",
});

bootstrapApplication(RootComponent, {
    providers: [
        provideRouter(routes),
        { provide: TitleStrategy, useClass: TypeDBPlatformTitleStrategy },
        provideHttpClient(),
        provideAnimations(),
        provideNgxStripe(),
        importProvidersFrom(
            provideFirebaseApp(() => initializeApp(firebaseOptions)),
            provideAuth(() => {
                const auth = getAuth();
                auth.tenantId = firebaseOptions.tenantId;
                return auth;
            }),
        ),
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            deps: [PlatformAuthService, FormService, FaIconLibrary, SanityService], /* fetch Sanity data eagerly for performance */
            multi: true,
        },
        {
            provide: MAT_DIALOG_SCROLL_STRATEGY,
            useFactory: () => {
                const overlay = inject(Overlay)
                return () => overlay.scrollStrategies.noop();
            }
        },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { width: "480px" } },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: "outline" } },
        { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: rippleGlobalConfig },
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: tooltipGlobalConfig },
        { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: checkboxGlobalConfig },
    ]
});
