<tp-page-scaffold [pageAvailability]="availability$ | async">
  <div class="action-bar">
    @if (breadcrumb$ | async; as breadcrumb) {
      <tp-breadcrumb [items]="breadcrumb.items" [url]="breadcrumb.url"/>
    }
  </div>
  <article>
    <section>
      <div class="section-title-row">
        <h2>Organization Settings</h2>
        <tp-button (click)="openEditOrgDialog()" buttonStyle="secondary stroke" buttonId="editOrg">
          <fa-icon [icon]="['fal', 'pen-to-square']"/> Edit
        </tp-button>
      </div>
      <tp-properties-table>
        <tp-properties-table-row key="Organization ID">{{ org.id }}</tp-properties-table-row>
        <tp-properties-table-row key="Organization Name">{{ org.name }}</tp-properties-table-row>
        <tp-properties-table-row key="Creation Date">{{ org.createdAt | datetime }}</tp-properties-table-row>
      </tp-properties-table>
    </section>

    <section>
      <h3 class="section-title-row">Upcoming Bill</h3>
      @if (upcomingInvoice$ | async; as upcomingInvoice) {
        @if (upcomingInvoice === "none") {
          <p>There is no upcoming bill.</p>
        } @else {
          <tp-properties-table>
            <tp-properties-table-row key="Period Beginning">{{ upcomingInvoice.startTime | datetime }}</tp-properties-table-row>
            <tp-properties-table-row key="Current Cost">{{ upcomingInvoice.total | currency }}</tp-properties-table-row>
          </tp-properties-table>
        }
      } @else {
        <tp-spinner/>
      }
    </section>

    <section>
      @if (cardSectionReady) {<div class="section-title-row os-payment-info">
        <h3>Credit Card</h3>
        @if (creditCard$ | async) {
          <tp-button (click)="openPaymentModal()" [enabled]="true" buttonStyle="secondary stroke" buttonId="updateCard">
            <fa-icon [icon]="['fal', 'pen-to-square']"/> Update Card Info
          </tp-button>
        }
      </div>
        @if (creditCard$ | async; as card) {
          <tp-properties-table title="Card Information">
            <tp-properties-table-row key="Type">{{ card | creditCardType }}</tp-properties-table-row>
            <tp-properties-table-row key="Cardholder Name">{{ card.name }}</tp-properties-table-row>
            <tp-properties-table-row key="Card Number">Ending in {{ card.lastFourDigits }}</tp-properties-table-row>
            <tp-properties-table-row key="Expiry Date">{{ card | creditCardExpiryDate }}</tp-properties-table-row>
            <tp-properties-table-row key="Billing Address">{{ card | billingAddress }}</tp-properties-table-row>
          </tp-properties-table>
        }
        @if (!(creditCard$ | async)) {
          <div>
            <tp-integration-card title="Add Card" [faIcon]="['fas', 'credit-card']" (click)="openPaymentModal()">
              <p>
                Billing will take place on a monthly basis from the creation of your first cluster.
                You may be charged earlier if the charge for a month exceeds $1000.
              </p>
            </tp-integration-card>
          </div>
        }
      } @else {
        <tp-spinner/>
      }
    </section>
    <section>
      <div class="section-title-row os-payment-info">
        <h3>Cloud Marketplace Integrations</h3>
      </div>
      <tp-marketplace-cards
        [gcpAccountPresent]="!!(activeGcpAccount$ | async)" [azureAccountPresent]="!!(azureAccount$ | async)"
        [awsAccountPresent]="!!(activeAwsAccount$ | async)" [canDisconnect]="true"
      />
      @if (activeGcpAccount$ | async; as account) {
        <tp-properties-table title="GCP Marketplace Integration Information">
          <tp-properties-table-row key="GCP Marketplace Order Number" matTooltip="Visit https://console.cloud.google.com/marketplace/orders to see your orders">{{ account.orderNumber ?? "" }}</tp-properties-table-row>
        </tp-properties-table>
      }
      @if (activeAwsAccount$ | async; as account) {
        <tp-properties-table title="AWS Marketplace Integration Information">
          <tp-properties-table-row key="AWS Account ID">{{ account.id }}</tp-properties-table-row>
        </tp-properties-table>
      }
      @if (azureAccount$ | async; as account) {
        <tp-properties-table title="Azure Marketplace Integration Information">
          <tp-properties-table-row key="Azure Marketplace Subscription">{{ account.name }}</tp-properties-table-row>
        </tp-properties-table>
      }
    </section>

    <section>
      <h3 class="section-title-row">Credits</h3>
      @if (credits.ready$ | async) {
        <tp-credits-table [table]="credits"/>
        <tp-table-paginator [table]="credits"/>
      } @else {
        <tp-spinner/>
      }
    </section>

    <section>
      <h3 class="section-title-row">Discounts</h3>
      @if (discounts.ready$ | async) {
        <tp-discounts-table [table]="discounts"/>
        <tp-table-paginator [table]="discounts"/>
      } @else {
        <tp-spinner/>
      }
    </section>

    <section>
      <h3 class="section-title-row">Invoices</h3>
      @if (invoices.ready$ | async) {
        <tp-invoices-table [table]="invoices"/>
        <tp-table-paginator [table]="invoices"/>
      } @else {
        <tp-spinner/>
      }
      <div class="billing-summary">
        <p>
          Questions about billing? Email us at
          <a href="mailto:billing@typedb.com">billing&#64;typedb.com</a>.
        </p>
      </div>
    </section>

    <tp-delete-resource-section
      title="Delete this organization"
      [display]="{ text: 'Once you delete an organization, there is no going back. Please be certain.' }"
      buttonText="Delete organization"
      (buttonPressed)="showContactSupportDialog()"
      [buttonIcon]="['fal', 'trash-can-xmark']"
    />
  </article>
</tp-page-scaffold>
