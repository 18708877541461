/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

export type LoadingTip = { title: string, body: string, code?: string }

export const loadingTips: LoadingTip[] = [
    {
        title: "TypeDB Tip:",
        body: "TypeDB’s rules let you automatically deduce new facts and relationships based on existing data, such as inheriting access in an IAM system.",
        code:
`define
  rule inherited-team-access:
    when {
      (team: $team, member: $member) isa team-membership;
      (subject: $team, object: $obj) isa admin-access;
    } then {
      (subject: $member, object: $obj) isa read-access;
      (subject: $member, object: $obj) isa write-access;
    };`
    },
    {
        title: "Did you know?",
        body: "Our default port for TypeDB (1729) was chosen because it's the second (and most famous) 'Taxicab Number' - as observed by mathematician Srinivasa Ramanujan.",
    },
    {
        title: "TypeDB Tip:",
        body: "Types can be variablized in queries, allowing for patterns representing complex structures.",
        code:
`match
  $user isa $user-type, has full-name $name;
  $user-type sub user;
fetch
  $name;
  $user-type;`
    },
    {
        title: "TypeDB Tip:",
        body: "TypeDB's parametric polymorphism allows queries to match any valid type, even with minimal information about it.",
        code:
`match
  $data isa $T;
  $data has data-expiration-date < 2023-09-27;
delete
  $data isa $T;`
    },
    {
        title: "Did you know?",
        body: "TypeDB can handle mathematical expressions in its queries to output transformed data.",
        code:
`match
  $dir isa directory,
    has path $path,
    has size $kb;
  ?gb = $kb / 1024 ^ 2;
fetch
  $path;
  ?gb;`
    },
    {
        title: "TypeDB Tip:",
        body: "Use inheritance polymorphism to share similarities between types.",
        code:
`define
  book sub entity, abstract,
    owns isbn-13 @key,
    owns isbn-10 @unique,
    owns title,
    owns genre,
    owns page-count,
    owns price;
  paperback sub book, owns stock;
  hardback sub book, owns stock;
  ebook sub book;`,
    },
    {
        title: "TypeDB Tip:",
        body: "As well as fetching attributes, TypeDB's fetch queries can also use subqueries for more complex results.",
        code:
`match
  $user isa user;
fetch
  $user: email, full-name, employee-id;
  teams: {
    match
      (team: $team, member: $user) isa team-membership;
    fetch
      $team: name;
  };`
    },
]
