/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.21.8
 * source: protocol/authentication-controller.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./common";
import * as dependency_2 from "./concept";
import * as pb_1 from "google-protobuf";
export class AuthenticationCtlProto extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): AuthenticationCtlProto {
        const message = new AuthenticationCtlProto({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AuthenticationCtlProto {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AuthenticationCtlProto();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AuthenticationCtlProto {
        return AuthenticationCtlProto.deserialize(bytes);
    }
}
export class AuthenticationCtlProtoPublic extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): AuthenticationCtlProtoPublic {
        const message = new AuthenticationCtlProtoPublic({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AuthenticationCtlProtoPublic {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AuthenticationCtlProtoPublic();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AuthenticationCtlProtoPublic {
        return AuthenticationCtlProtoPublic.deserialize(bytes);
    }
}
export class AuthenticationCtlProtoPublicReq extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3, 4, 5, 6]];
    constructor(data?: any[] | ({} & (({
        startAdminAuth?: AuthenticationCtlProtoStartAuthReq;
        authAdminToken?: never;
        startUserAuth?: never;
        authUserToken?: never;
        sendResetPassword?: never;
        getProviderForEmail?: never;
    } | {
        startAdminAuth?: never;
        authAdminToken?: AuthenticationCtlProtoAuthTokenReq;
        startUserAuth?: never;
        authUserToken?: never;
        sendResetPassword?: never;
        getProviderForEmail?: never;
    } | {
        startAdminAuth?: never;
        authAdminToken?: never;
        startUserAuth?: AuthenticationCtlProtoStartAuthReq;
        authUserToken?: never;
        sendResetPassword?: never;
        getProviderForEmail?: never;
    } | {
        startAdminAuth?: never;
        authAdminToken?: never;
        startUserAuth?: never;
        authUserToken?: AuthenticationCtlProtoAuthTokenReq;
        sendResetPassword?: never;
        getProviderForEmail?: never;
    } | {
        startAdminAuth?: never;
        authAdminToken?: never;
        startUserAuth?: never;
        authUserToken?: never;
        sendResetPassword?: AuthenticationCtlProtoSendResetPasswordReq;
        getProviderForEmail?: never;
    } | {
        startAdminAuth?: never;
        authAdminToken?: never;
        startUserAuth?: never;
        authUserToken?: never;
        sendResetPassword?: never;
        getProviderForEmail?: AuthenticationCtlProtoGetProviderForEmailReq;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("startAdminAuth" in data && data.startAdminAuth != undefined) {
                this.startAdminAuth = data.startAdminAuth;
            }
            if ("authAdminToken" in data && data.authAdminToken != undefined) {
                this.authAdminToken = data.authAdminToken;
            }
            if ("startUserAuth" in data && data.startUserAuth != undefined) {
                this.startUserAuth = data.startUserAuth;
            }
            if ("authUserToken" in data && data.authUserToken != undefined) {
                this.authUserToken = data.authUserToken;
            }
            if ("sendResetPassword" in data && data.sendResetPassword != undefined) {
                this.sendResetPassword = data.sendResetPassword;
            }
            if ("getProviderForEmail" in data && data.getProviderForEmail != undefined) {
                this.getProviderForEmail = data.getProviderForEmail;
            }
        }
    }
    get startAdminAuth() {
        return pb_1.Message.getWrapperField(this, AuthenticationCtlProtoStartAuthReq, 1) as AuthenticationCtlProtoStartAuthReq;
    }
    set startAdminAuth(value: AuthenticationCtlProtoStartAuthReq) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
    }
    get hasStartAdminAuth() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get authAdminToken() {
        return pb_1.Message.getWrapperField(this, AuthenticationCtlProtoAuthTokenReq, 2) as AuthenticationCtlProtoAuthTokenReq;
    }
    set authAdminToken(value: AuthenticationCtlProtoAuthTokenReq) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get hasAuthAdminToken() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get startUserAuth() {
        return pb_1.Message.getWrapperField(this, AuthenticationCtlProtoStartAuthReq, 3) as AuthenticationCtlProtoStartAuthReq;
    }
    set startUserAuth(value: AuthenticationCtlProtoStartAuthReq) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0], value);
    }
    get hasStartUserAuth() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get authUserToken() {
        return pb_1.Message.getWrapperField(this, AuthenticationCtlProtoAuthTokenReq, 4) as AuthenticationCtlProtoAuthTokenReq;
    }
    set authUserToken(value: AuthenticationCtlProtoAuthTokenReq) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
    }
    get hasAuthUserToken() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get sendResetPassword() {
        return pb_1.Message.getWrapperField(this, AuthenticationCtlProtoSendResetPasswordReq, 5) as AuthenticationCtlProtoSendResetPasswordReq;
    }
    set sendResetPassword(value: AuthenticationCtlProtoSendResetPasswordReq) {
        pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0], value);
    }
    get hasSendResetPassword() {
        return pb_1.Message.getField(this, 5) != null;
    }
    get getProviderForEmail() {
        return pb_1.Message.getWrapperField(this, AuthenticationCtlProtoGetProviderForEmailReq, 6) as AuthenticationCtlProtoGetProviderForEmailReq;
    }
    set getProviderForEmail(value: AuthenticationCtlProtoGetProviderForEmailReq) {
        pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0], value);
    }
    get hasGetProviderForEmail() {
        return pb_1.Message.getField(this, 6) != null;
    }
    get req() {
        const cases: {
            [index: number]: "none" | "startAdminAuth" | "authAdminToken" | "startUserAuth" | "authUserToken" | "sendResetPassword" | "getProviderForEmail";
        } = {
            0: "none",
            1: "startAdminAuth",
            2: "authAdminToken",
            3: "startUserAuth",
            4: "authUserToken",
            5: "sendResetPassword",
            6: "getProviderForEmail"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 4, 5, 6])];
    }
    static fromObject(data: {
        startAdminAuth?: ReturnType<typeof AuthenticationCtlProtoStartAuthReq.prototype.toObject>;
        authAdminToken?: ReturnType<typeof AuthenticationCtlProtoAuthTokenReq.prototype.toObject>;
        startUserAuth?: ReturnType<typeof AuthenticationCtlProtoStartAuthReq.prototype.toObject>;
        authUserToken?: ReturnType<typeof AuthenticationCtlProtoAuthTokenReq.prototype.toObject>;
        sendResetPassword?: ReturnType<typeof AuthenticationCtlProtoSendResetPasswordReq.prototype.toObject>;
        getProviderForEmail?: ReturnType<typeof AuthenticationCtlProtoGetProviderForEmailReq.prototype.toObject>;
    }): AuthenticationCtlProtoPublicReq {
        const message = new AuthenticationCtlProtoPublicReq({});
        if (data.startAdminAuth != null) {
            message.startAdminAuth = AuthenticationCtlProtoStartAuthReq.fromObject(data.startAdminAuth);
        }
        if (data.authAdminToken != null) {
            message.authAdminToken = AuthenticationCtlProtoAuthTokenReq.fromObject(data.authAdminToken);
        }
        if (data.startUserAuth != null) {
            message.startUserAuth = AuthenticationCtlProtoStartAuthReq.fromObject(data.startUserAuth);
        }
        if (data.authUserToken != null) {
            message.authUserToken = AuthenticationCtlProtoAuthTokenReq.fromObject(data.authUserToken);
        }
        if (data.sendResetPassword != null) {
            message.sendResetPassword = AuthenticationCtlProtoSendResetPasswordReq.fromObject(data.sendResetPassword);
        }
        if (data.getProviderForEmail != null) {
            message.getProviderForEmail = AuthenticationCtlProtoGetProviderForEmailReq.fromObject(data.getProviderForEmail);
        }
        return message;
    }
    toObject() {
        const data: {
            startAdminAuth?: ReturnType<typeof AuthenticationCtlProtoStartAuthReq.prototype.toObject>;
            authAdminToken?: ReturnType<typeof AuthenticationCtlProtoAuthTokenReq.prototype.toObject>;
            startUserAuth?: ReturnType<typeof AuthenticationCtlProtoStartAuthReq.prototype.toObject>;
            authUserToken?: ReturnType<typeof AuthenticationCtlProtoAuthTokenReq.prototype.toObject>;
            sendResetPassword?: ReturnType<typeof AuthenticationCtlProtoSendResetPasswordReq.prototype.toObject>;
            getProviderForEmail?: ReturnType<typeof AuthenticationCtlProtoGetProviderForEmailReq.prototype.toObject>;
        } = {};
        if (this.startAdminAuth != null) {
            data.startAdminAuth = this.startAdminAuth.toObject();
        }
        if (this.authAdminToken != null) {
            data.authAdminToken = this.authAdminToken.toObject();
        }
        if (this.startUserAuth != null) {
            data.startUserAuth = this.startUserAuth.toObject();
        }
        if (this.authUserToken != null) {
            data.authUserToken = this.authUserToken.toObject();
        }
        if (this.sendResetPassword != null) {
            data.sendResetPassword = this.sendResetPassword.toObject();
        }
        if (this.getProviderForEmail != null) {
            data.getProviderForEmail = this.getProviderForEmail.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasStartAdminAuth)
            writer.writeMessage(1, this.startAdminAuth, () => this.startAdminAuth.serialize(writer));
        if (this.hasAuthAdminToken)
            writer.writeMessage(2, this.authAdminToken, () => this.authAdminToken.serialize(writer));
        if (this.hasStartUserAuth)
            writer.writeMessage(3, this.startUserAuth, () => this.startUserAuth.serialize(writer));
        if (this.hasAuthUserToken)
            writer.writeMessage(4, this.authUserToken, () => this.authUserToken.serialize(writer));
        if (this.hasSendResetPassword)
            writer.writeMessage(5, this.sendResetPassword, () => this.sendResetPassword.serialize(writer));
        if (this.hasGetProviderForEmail)
            writer.writeMessage(6, this.getProviderForEmail, () => this.getProviderForEmail.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AuthenticationCtlProtoPublicReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AuthenticationCtlProtoPublicReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.startAdminAuth, () => message.startAdminAuth = AuthenticationCtlProtoStartAuthReq.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.authAdminToken, () => message.authAdminToken = AuthenticationCtlProtoAuthTokenReq.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.startUserAuth, () => message.startUserAuth = AuthenticationCtlProtoStartAuthReq.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.authUserToken, () => message.authUserToken = AuthenticationCtlProtoAuthTokenReq.deserialize(reader));
                    break;
                case 5:
                    reader.readMessage(message.sendResetPassword, () => message.sendResetPassword = AuthenticationCtlProtoSendResetPasswordReq.deserialize(reader));
                    break;
                case 6:
                    reader.readMessage(message.getProviderForEmail, () => message.getProviderForEmail = AuthenticationCtlProtoGetProviderForEmailReq.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AuthenticationCtlProtoPublicReq {
        return AuthenticationCtlProtoPublicReq.deserialize(bytes);
    }
}
export class AuthenticationCtlProtoPublicRes extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3, 4, 6]];
    constructor(data?: any[] | ({} & (({
        startAdminAuth?: AuthenticationCtlProtoStartAuthRes;
        authAdminToken?: never;
        startUserAuth?: never;
        authUserToken?: never;
        getProviderForEmail?: never;
    } | {
        startAdminAuth?: never;
        authAdminToken?: AuthenticationCtlProtoAuthTokenRes;
        startUserAuth?: never;
        authUserToken?: never;
        getProviderForEmail?: never;
    } | {
        startAdminAuth?: never;
        authAdminToken?: never;
        startUserAuth?: AuthenticationCtlProtoStartAuthRes;
        authUserToken?: never;
        getProviderForEmail?: never;
    } | {
        startAdminAuth?: never;
        authAdminToken?: never;
        startUserAuth?: never;
        authUserToken?: AuthenticationCtlProtoAuthTokenRes;
        getProviderForEmail?: never;
    } | {
        startAdminAuth?: never;
        authAdminToken?: never;
        startUserAuth?: never;
        authUserToken?: never;
        getProviderForEmail?: AuthenticationCtlProtoGetProviderForEmailRes;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("startAdminAuth" in data && data.startAdminAuth != undefined) {
                this.startAdminAuth = data.startAdminAuth;
            }
            if ("authAdminToken" in data && data.authAdminToken != undefined) {
                this.authAdminToken = data.authAdminToken;
            }
            if ("startUserAuth" in data && data.startUserAuth != undefined) {
                this.startUserAuth = data.startUserAuth;
            }
            if ("authUserToken" in data && data.authUserToken != undefined) {
                this.authUserToken = data.authUserToken;
            }
            if ("getProviderForEmail" in data && data.getProviderForEmail != undefined) {
                this.getProviderForEmail = data.getProviderForEmail;
            }
        }
    }
    get startAdminAuth() {
        return pb_1.Message.getWrapperField(this, AuthenticationCtlProtoStartAuthRes, 1) as AuthenticationCtlProtoStartAuthRes;
    }
    set startAdminAuth(value: AuthenticationCtlProtoStartAuthRes) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
    }
    get hasStartAdminAuth() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get authAdminToken() {
        return pb_1.Message.getWrapperField(this, AuthenticationCtlProtoAuthTokenRes, 2) as AuthenticationCtlProtoAuthTokenRes;
    }
    set authAdminToken(value: AuthenticationCtlProtoAuthTokenRes) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get hasAuthAdminToken() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get startUserAuth() {
        return pb_1.Message.getWrapperField(this, AuthenticationCtlProtoStartAuthRes, 3) as AuthenticationCtlProtoStartAuthRes;
    }
    set startUserAuth(value: AuthenticationCtlProtoStartAuthRes) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0], value);
    }
    get hasStartUserAuth() {
        return pb_1.Message.getField(this, 3) != null;
    }
    get authUserToken() {
        return pb_1.Message.getWrapperField(this, AuthenticationCtlProtoAuthTokenRes, 4) as AuthenticationCtlProtoAuthTokenRes;
    }
    set authUserToken(value: AuthenticationCtlProtoAuthTokenRes) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
    }
    get hasAuthUserToken() {
        return pb_1.Message.getField(this, 4) != null;
    }
    get getProviderForEmail() {
        return pb_1.Message.getWrapperField(this, AuthenticationCtlProtoGetProviderForEmailRes, 6) as AuthenticationCtlProtoGetProviderForEmailRes;
    }
    set getProviderForEmail(value: AuthenticationCtlProtoGetProviderForEmailRes) {
        pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0], value);
    }
    get hasGetProviderForEmail() {
        return pb_1.Message.getField(this, 6) != null;
    }
    get res() {
        const cases: {
            [index: number]: "none" | "startAdminAuth" | "authAdminToken" | "startUserAuth" | "authUserToken" | "getProviderForEmail";
        } = {
            0: "none",
            1: "startAdminAuth",
            2: "authAdminToken",
            3: "startUserAuth",
            4: "authUserToken",
            6: "getProviderForEmail"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 4, 6])];
    }
    static fromObject(data: {
        startAdminAuth?: ReturnType<typeof AuthenticationCtlProtoStartAuthRes.prototype.toObject>;
        authAdminToken?: ReturnType<typeof AuthenticationCtlProtoAuthTokenRes.prototype.toObject>;
        startUserAuth?: ReturnType<typeof AuthenticationCtlProtoStartAuthRes.prototype.toObject>;
        authUserToken?: ReturnType<typeof AuthenticationCtlProtoAuthTokenRes.prototype.toObject>;
        getProviderForEmail?: ReturnType<typeof AuthenticationCtlProtoGetProviderForEmailRes.prototype.toObject>;
    }): AuthenticationCtlProtoPublicRes {
        const message = new AuthenticationCtlProtoPublicRes({});
        if (data.startAdminAuth != null) {
            message.startAdminAuth = AuthenticationCtlProtoStartAuthRes.fromObject(data.startAdminAuth);
        }
        if (data.authAdminToken != null) {
            message.authAdminToken = AuthenticationCtlProtoAuthTokenRes.fromObject(data.authAdminToken);
        }
        if (data.startUserAuth != null) {
            message.startUserAuth = AuthenticationCtlProtoStartAuthRes.fromObject(data.startUserAuth);
        }
        if (data.authUserToken != null) {
            message.authUserToken = AuthenticationCtlProtoAuthTokenRes.fromObject(data.authUserToken);
        }
        if (data.getProviderForEmail != null) {
            message.getProviderForEmail = AuthenticationCtlProtoGetProviderForEmailRes.fromObject(data.getProviderForEmail);
        }
        return message;
    }
    toObject() {
        const data: {
            startAdminAuth?: ReturnType<typeof AuthenticationCtlProtoStartAuthRes.prototype.toObject>;
            authAdminToken?: ReturnType<typeof AuthenticationCtlProtoAuthTokenRes.prototype.toObject>;
            startUserAuth?: ReturnType<typeof AuthenticationCtlProtoStartAuthRes.prototype.toObject>;
            authUserToken?: ReturnType<typeof AuthenticationCtlProtoAuthTokenRes.prototype.toObject>;
            getProviderForEmail?: ReturnType<typeof AuthenticationCtlProtoGetProviderForEmailRes.prototype.toObject>;
        } = {};
        if (this.startAdminAuth != null) {
            data.startAdminAuth = this.startAdminAuth.toObject();
        }
        if (this.authAdminToken != null) {
            data.authAdminToken = this.authAdminToken.toObject();
        }
        if (this.startUserAuth != null) {
            data.startUserAuth = this.startUserAuth.toObject();
        }
        if (this.authUserToken != null) {
            data.authUserToken = this.authUserToken.toObject();
        }
        if (this.getProviderForEmail != null) {
            data.getProviderForEmail = this.getProviderForEmail.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasStartAdminAuth)
            writer.writeMessage(1, this.startAdminAuth, () => this.startAdminAuth.serialize(writer));
        if (this.hasAuthAdminToken)
            writer.writeMessage(2, this.authAdminToken, () => this.authAdminToken.serialize(writer));
        if (this.hasStartUserAuth)
            writer.writeMessage(3, this.startUserAuth, () => this.startUserAuth.serialize(writer));
        if (this.hasAuthUserToken)
            writer.writeMessage(4, this.authUserToken, () => this.authUserToken.serialize(writer));
        if (this.hasGetProviderForEmail)
            writer.writeMessage(6, this.getProviderForEmail, () => this.getProviderForEmail.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AuthenticationCtlProtoPublicRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AuthenticationCtlProtoPublicRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.startAdminAuth, () => message.startAdminAuth = AuthenticationCtlProtoStartAuthRes.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.authAdminToken, () => message.authAdminToken = AuthenticationCtlProtoAuthTokenRes.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.startUserAuth, () => message.startUserAuth = AuthenticationCtlProtoStartAuthRes.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.authUserToken, () => message.authUserToken = AuthenticationCtlProtoAuthTokenRes.deserialize(reader));
                    break;
                case 6:
                    reader.readMessage(message.getProviderForEmail, () => message.getProviderForEmail = AuthenticationCtlProtoGetProviderForEmailRes.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AuthenticationCtlProtoPublicRes {
        return AuthenticationCtlProtoPublicRes.deserialize(bytes);
    }
}
export class AuthenticationCtlProtoPrivate extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): AuthenticationCtlProtoPrivate {
        const message = new AuthenticationCtlProtoPrivate({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AuthenticationCtlProtoPrivate {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AuthenticationCtlProtoPrivate();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AuthenticationCtlProtoPrivate {
        return AuthenticationCtlProtoPrivate.deserialize(bytes);
    }
}
export class AuthenticationCtlProtoPrivateReq extends pb_1.Message {
    #one_of_decls: number[][] = [[1]];
    constructor(data?: any[] | ({} & (({
        deauthUser?: AuthenticationCtlProtoDeauthUserReq;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("deauthUser" in data && data.deauthUser != undefined) {
                this.deauthUser = data.deauthUser;
            }
        }
    }
    get deauthUser() {
        return pb_1.Message.getWrapperField(this, AuthenticationCtlProtoDeauthUserReq, 1) as AuthenticationCtlProtoDeauthUserReq;
    }
    set deauthUser(value: AuthenticationCtlProtoDeauthUserReq) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
    }
    get hasDeauthUser() {
        return pb_1.Message.getField(this, 1) != null;
    }
    get req() {
        const cases: {
            [index: number]: "none" | "deauthUser";
        } = {
            0: "none",
            1: "deauthUser"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1])];
    }
    static fromObject(data: {
        deauthUser?: ReturnType<typeof AuthenticationCtlProtoDeauthUserReq.prototype.toObject>;
    }): AuthenticationCtlProtoPrivateReq {
        const message = new AuthenticationCtlProtoPrivateReq({});
        if (data.deauthUser != null) {
            message.deauthUser = AuthenticationCtlProtoDeauthUserReq.fromObject(data.deauthUser);
        }
        return message;
    }
    toObject() {
        const data: {
            deauthUser?: ReturnType<typeof AuthenticationCtlProtoDeauthUserReq.prototype.toObject>;
        } = {};
        if (this.deauthUser != null) {
            data.deauthUser = this.deauthUser.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasDeauthUser)
            writer.writeMessage(1, this.deauthUser, () => this.deauthUser.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AuthenticationCtlProtoPrivateReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AuthenticationCtlProtoPrivateReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.deauthUser, () => message.deauthUser = AuthenticationCtlProtoDeauthUserReq.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AuthenticationCtlProtoPrivateReq {
        return AuthenticationCtlProtoPrivateReq.deserialize(bytes);
    }
}
export class AuthenticationCtlProtoPrivateRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): AuthenticationCtlProtoPrivateRes {
        const message = new AuthenticationCtlProtoPrivateRes({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AuthenticationCtlProtoPrivateRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AuthenticationCtlProtoPrivateRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AuthenticationCtlProtoPrivateRes {
        return AuthenticationCtlProtoPrivateRes.deserialize(bytes);
    }
}
export class AuthenticationCtlProtoStartAuth extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): AuthenticationCtlProtoStartAuth {
        const message = new AuthenticationCtlProtoStartAuth({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AuthenticationCtlProtoStartAuth {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AuthenticationCtlProtoStartAuth();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AuthenticationCtlProtoStartAuth {
        return AuthenticationCtlProtoStartAuth.deserialize(bytes);
    }
}
export class AuthenticationCtlProtoStartAuthReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): AuthenticationCtlProtoStartAuthReq {
        const message = new AuthenticationCtlProtoStartAuthReq({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AuthenticationCtlProtoStartAuthReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AuthenticationCtlProtoStartAuthReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AuthenticationCtlProtoStartAuthReq {
        return AuthenticationCtlProtoStartAuthReq.deserialize(bytes);
    }
}
export class AuthenticationCtlProtoStartAuthRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        serviceId?: string;
        apiKey?: string;
        authDomain?: string;
        posthogApiKey?: string;
        cioTrackApiToken?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("serviceId" in data && data.serviceId != undefined) {
                this.serviceId = data.serviceId;
            }
            if ("apiKey" in data && data.apiKey != undefined) {
                this.apiKey = data.apiKey;
            }
            if ("authDomain" in data && data.authDomain != undefined) {
                this.authDomain = data.authDomain;
            }
            if ("posthogApiKey" in data && data.posthogApiKey != undefined) {
                this.posthogApiKey = data.posthogApiKey;
            }
            if ("cioTrackApiToken" in data && data.cioTrackApiToken != undefined) {
                this.cioTrackApiToken = data.cioTrackApiToken;
            }
        }
    }
    get serviceId() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set serviceId(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get apiKey() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set apiKey(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get authDomain() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set authDomain(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get posthogApiKey() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set posthogApiKey(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get cioTrackApiToken() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set cioTrackApiToken(value: string) {
        pb_1.Message.setField(this, 5, value);
    }
    static fromObject(data: {
        serviceId?: string;
        apiKey?: string;
        authDomain?: string;
        posthogApiKey?: string;
        cioTrackApiToken?: string;
    }): AuthenticationCtlProtoStartAuthRes {
        const message = new AuthenticationCtlProtoStartAuthRes({});
        if (data.serviceId != null) {
            message.serviceId = data.serviceId;
        }
        if (data.apiKey != null) {
            message.apiKey = data.apiKey;
        }
        if (data.authDomain != null) {
            message.authDomain = data.authDomain;
        }
        if (data.posthogApiKey != null) {
            message.posthogApiKey = data.posthogApiKey;
        }
        if (data.cioTrackApiToken != null) {
            message.cioTrackApiToken = data.cioTrackApiToken;
        }
        return message;
    }
    toObject() {
        const data: {
            serviceId?: string;
            apiKey?: string;
            authDomain?: string;
            posthogApiKey?: string;
            cioTrackApiToken?: string;
        } = {};
        if (this.serviceId != null) {
            data.serviceId = this.serviceId;
        }
        if (this.apiKey != null) {
            data.apiKey = this.apiKey;
        }
        if (this.authDomain != null) {
            data.authDomain = this.authDomain;
        }
        if (this.posthogApiKey != null) {
            data.posthogApiKey = this.posthogApiKey;
        }
        if (this.cioTrackApiToken != null) {
            data.cioTrackApiToken = this.cioTrackApiToken;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.serviceId.length)
            writer.writeString(1, this.serviceId);
        if (this.apiKey.length)
            writer.writeString(2, this.apiKey);
        if (this.authDomain.length)
            writer.writeString(3, this.authDomain);
        if (this.posthogApiKey.length)
            writer.writeString(4, this.posthogApiKey);
        if (this.cioTrackApiToken.length)
            writer.writeString(5, this.cioTrackApiToken);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AuthenticationCtlProtoStartAuthRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AuthenticationCtlProtoStartAuthRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.serviceId = reader.readString();
                    break;
                case 2:
                    message.apiKey = reader.readString();
                    break;
                case 3:
                    message.authDomain = reader.readString();
                    break;
                case 4:
                    message.posthogApiKey = reader.readString();
                    break;
                case 5:
                    message.cioTrackApiToken = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AuthenticationCtlProtoStartAuthRes {
        return AuthenticationCtlProtoStartAuthRes.deserialize(bytes);
    }
}
export class AuthenticationCtlProtoAuthToken extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): AuthenticationCtlProtoAuthToken {
        const message = new AuthenticationCtlProtoAuthToken({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AuthenticationCtlProtoAuthToken {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AuthenticationCtlProtoAuthToken();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AuthenticationCtlProtoAuthToken {
        return AuthenticationCtlProtoAuthToken.deserialize(bytes);
    }
}
export class AuthenticationCtlProtoAuthTokenReq extends pb_1.Message {
    #one_of_decls: number[][] = [[2]];
    constructor(data?: any[] | ({
        token?: string;
    } & (({
        email?: string;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("token" in data && data.token != undefined) {
                this.token = data.token;
            }
            if ("email" in data && data.email != undefined) {
                this.email = data.email;
            }
        }
    }
    get token() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set token(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get email() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set email(value: string) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0], value);
    }
    get hasEmail() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get _email() {
        const cases: {
            [index: number]: "none" | "email";
        } = {
            0: "none",
            2: "email"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2])];
    }
    static fromObject(data: {
        token?: string;
        email?: string;
    }): AuthenticationCtlProtoAuthTokenReq {
        const message = new AuthenticationCtlProtoAuthTokenReq({});
        if (data.token != null) {
            message.token = data.token;
        }
        if (data.email != null) {
            message.email = data.email;
        }
        return message;
    }
    toObject() {
        const data: {
            token?: string;
            email?: string;
        } = {};
        if (this.token != null) {
            data.token = this.token;
        }
        if (this.email != null) {
            data.email = this.email;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.token.length)
            writer.writeString(1, this.token);
        if (this.hasEmail)
            writer.writeString(2, this.email);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AuthenticationCtlProtoAuthTokenReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AuthenticationCtlProtoAuthTokenReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.token = reader.readString();
                    break;
                case 2:
                    message.email = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AuthenticationCtlProtoAuthTokenReq {
        return AuthenticationCtlProtoAuthTokenReq.deserialize(bytes);
    }
}
export class AuthenticationCtlProtoAuthTokenRes extends pb_1.Message {
    #one_of_decls: number[][] = [[2]];
    constructor(data?: any[] | ({
        accountStatus?: dependency_1.AccountStatusProto;
    } & (({
        user?: dependency_2.UserProto;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("accountStatus" in data && data.accountStatus != undefined) {
                this.accountStatus = data.accountStatus;
            }
            if ("user" in data && data.user != undefined) {
                this.user = data.user;
            }
        }
    }
    get accountStatus() {
        return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.AccountStatusProto.INVALID) as dependency_1.AccountStatusProto;
    }
    set accountStatus(value: dependency_1.AccountStatusProto) {
        pb_1.Message.setField(this, 1, value);
    }
    get user() {
        return pb_1.Message.getWrapperField(this, dependency_2.UserProto, 2) as dependency_2.UserProto;
    }
    set user(value: dependency_2.UserProto) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get hasUser() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get _user() {
        const cases: {
            [index: number]: "none" | "user";
        } = {
            0: "none",
            2: "user"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2])];
    }
    static fromObject(data: {
        accountStatus?: dependency_1.AccountStatusProto;
        user?: ReturnType<typeof dependency_2.UserProto.prototype.toObject>;
    }): AuthenticationCtlProtoAuthTokenRes {
        const message = new AuthenticationCtlProtoAuthTokenRes({});
        if (data.accountStatus != null) {
            message.accountStatus = data.accountStatus;
        }
        if (data.user != null) {
            message.user = dependency_2.UserProto.fromObject(data.user);
        }
        return message;
    }
    toObject() {
        const data: {
            accountStatus?: dependency_1.AccountStatusProto;
            user?: ReturnType<typeof dependency_2.UserProto.prototype.toObject>;
        } = {};
        if (this.accountStatus != null) {
            data.accountStatus = this.accountStatus;
        }
        if (this.user != null) {
            data.user = this.user.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.accountStatus != dependency_1.AccountStatusProto.INVALID)
            writer.writeEnum(1, this.accountStatus);
        if (this.hasUser)
            writer.writeMessage(2, this.user, () => this.user.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AuthenticationCtlProtoAuthTokenRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AuthenticationCtlProtoAuthTokenRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.accountStatus = reader.readEnum();
                    break;
                case 2:
                    reader.readMessage(message.user, () => message.user = dependency_2.UserProto.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AuthenticationCtlProtoAuthTokenRes {
        return AuthenticationCtlProtoAuthTokenRes.deserialize(bytes);
    }
}
export class AuthenticationCtlProtoDeauthUser extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): AuthenticationCtlProtoDeauthUser {
        const message = new AuthenticationCtlProtoDeauthUser({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AuthenticationCtlProtoDeauthUser {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AuthenticationCtlProtoDeauthUser();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AuthenticationCtlProtoDeauthUser {
        return AuthenticationCtlProtoDeauthUser.deserialize(bytes);
    }
}
export class AuthenticationCtlProtoDeauthUserReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): AuthenticationCtlProtoDeauthUserReq {
        const message = new AuthenticationCtlProtoDeauthUserReq({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AuthenticationCtlProtoDeauthUserReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AuthenticationCtlProtoDeauthUserReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AuthenticationCtlProtoDeauthUserReq {
        return AuthenticationCtlProtoDeauthUserReq.deserialize(bytes);
    }
}
export class AuthenticationCtlProtoSendResetPassword extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): AuthenticationCtlProtoSendResetPassword {
        const message = new AuthenticationCtlProtoSendResetPassword({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AuthenticationCtlProtoSendResetPassword {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AuthenticationCtlProtoSendResetPassword();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AuthenticationCtlProtoSendResetPassword {
        return AuthenticationCtlProtoSendResetPassword.deserialize(bytes);
    }
}
export class AuthenticationCtlProtoSendResetPasswordReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        email?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("email" in data && data.email != undefined) {
                this.email = data.email;
            }
        }
    }
    get email() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set email(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        email?: string;
    }): AuthenticationCtlProtoSendResetPasswordReq {
        const message = new AuthenticationCtlProtoSendResetPasswordReq({});
        if (data.email != null) {
            message.email = data.email;
        }
        return message;
    }
    toObject() {
        const data: {
            email?: string;
        } = {};
        if (this.email != null) {
            data.email = this.email;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.email.length)
            writer.writeString(1, this.email);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AuthenticationCtlProtoSendResetPasswordReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AuthenticationCtlProtoSendResetPasswordReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.email = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AuthenticationCtlProtoSendResetPasswordReq {
        return AuthenticationCtlProtoSendResetPasswordReq.deserialize(bytes);
    }
}
export class AuthenticationCtlProtoGetProviderForEmail extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): AuthenticationCtlProtoGetProviderForEmail {
        const message = new AuthenticationCtlProtoGetProviderForEmail({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AuthenticationCtlProtoGetProviderForEmail {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AuthenticationCtlProtoGetProviderForEmail();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AuthenticationCtlProtoGetProviderForEmail {
        return AuthenticationCtlProtoGetProviderForEmail.deserialize(bytes);
    }
}
export class AuthenticationCtlProtoGetProviderForEmailReq extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        email?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("email" in data && data.email != undefined) {
                this.email = data.email;
            }
        }
    }
    get email() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set email(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        email?: string;
    }): AuthenticationCtlProtoGetProviderForEmailReq {
        const message = new AuthenticationCtlProtoGetProviderForEmailReq({});
        if (data.email != null) {
            message.email = data.email;
        }
        return message;
    }
    toObject() {
        const data: {
            email?: string;
        } = {};
        if (this.email != null) {
            data.email = this.email;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.email.length)
            writer.writeString(1, this.email);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AuthenticationCtlProtoGetProviderForEmailReq {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AuthenticationCtlProtoGetProviderForEmailReq();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.email = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AuthenticationCtlProtoGetProviderForEmailReq {
        return AuthenticationCtlProtoGetProviderForEmailReq.deserialize(bytes);
    }
}
export class AuthenticationCtlProtoGetProviderForEmailRes extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        provider?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("provider" in data && data.provider != undefined) {
                this.provider = data.provider;
            }
        }
    }
    get provider() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set provider(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        provider?: string;
    }): AuthenticationCtlProtoGetProviderForEmailRes {
        const message = new AuthenticationCtlProtoGetProviderForEmailRes({});
        if (data.provider != null) {
            message.provider = data.provider;
        }
        return message;
    }
    toObject() {
        const data: {
            provider?: string;
        } = {};
        if (this.provider != null) {
            data.provider = this.provider;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.provider.length)
            writer.writeString(1, this.provider);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AuthenticationCtlProtoGetProviderForEmailRes {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AuthenticationCtlProtoGetProviderForEmailRes();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.provider = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AuthenticationCtlProtoGetProviderForEmailRes {
        return AuthenticationCtlProtoGetProviderForEmailRes.deserialize(bytes);
    }
}
